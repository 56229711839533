import "./Redeem.css";
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import InputBox from "../../components/InputBox/InputBox.js";
import YellowButton from "../../components/ButtonYellow/buttonYellow";
import Button from "../../components/ButtonWhite/buttonWhite";
import axios from "axios";
import Cookies from "universal-cookie";

function Redeem() {
  const [pvh, setpvh] = useState(0)
  const [vha, setvha] = useState(0)
  const [vhr, setvhr] = useState(0)

  const [text, setText] = useState("Redeem Your Volunteer Hours") 
  const [elements, setElements] = useState(
    <div>
      <div id="redeemDiv">
          <InputBox
            label="Enter your hours"
            id="redeemInput"
            type="number"
            min="0"
            placeholder="0"
          />
        </div>
        <div id="redeemBtnDiv">
          <div id="incrementInputBtns">
            <Button
              content="+1"
              id="increase"
              Height={"50px"}
              Width={"100px"}
              func={increase}
            ></Button>
            <Button
              content="-1"
              id="decrease"
              Height={"50px"}
              Width={"100px"}
              func={decrease}
            ></Button>
          </div>
          <YellowButton
            content="Redeem"
            id="redeem"
            Height={"100px"}
            Width={"250px"}
            func={redeemHours}
          ></YellowButton>
        </div>
    </div>
  )

  const cookies = new Cookies();
  const session = cookies.get("session");

  useEffect(async()=>{
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/userinfo`;
    if (window.location.hostname == "localhost")
    postURL = `${window.location.protocol}//${window.location.hostname}:2000/userinfo`;
    let userinfo = await axios.post(postURL, { session: session });

    if (userinfo.data.code == 404) {
      window.location.replace("/404");
    }
    
    const user = userinfo.data.payload

    setpvh( Math.round(user.PendingVolunteerHours * 100) / 100 )
    setvha( Math.round(user.VolunteerHoursEarned * 100) / 100 )
    setvhr( Math.round(user.VolunteerHoursRedeemed * 100) / 100 )

  })

  function increase() {
    const input = document.getElementById("redeemInput");
    input.value = Number(input.value) + 1;
  }

  function decrease() {
    const input = document.getElementById("redeemInput");
    if (Number(input.value) > 0) input.value = Number(input.value) - 1;
  }

  function download() {
    let currentDate = new Date()
    let imageToDownload = document.getElementById("certificate");
    const link = document.createElement('a')
    link.href = imageToDownload.src
    link.download = `Botsitivity-Certificate-${currentDate.getDate()}-${currentDate.getMonth()}-${currentDate.getFullYear()}-${currentDate.getMilliseconds()}.png`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function redeemHours() {
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/certificate`;
    if (window.location.hostname == "localhost")
    postURL = `${window.location.protocol}//${window.location.hostname}:2000/certificate`;

    setText("Waiting for certificate...")

    setElements("")

    let certificate = await axios.post(postURL, { session: session, redeemAmt: Number(document.getElementById("redeemInput").value)  });

    console.log(certificate)

    let valid = false


    if (certificate.data.code){
      setText(certificate.data.message)
    }else{
      console.log("in here")
      setText("Redeemed Volunteer Hours!")
  
      setElements(
        <div className="certificateDownloadContainer">
          <div className="displaycertificate">
            <img id="certificate" src={"data:image/png;base64," + certificate.data}></img>
          </div>
          <YellowButton
            content="Download"
            id="redeem"
            Height={"100px"}
            Width={"250px"}
            func={download}
          ></YellowButton>
        </div>
      )
    }


  }

  return (
    <div className="redeemBackground">
      <Menu />
      <div className="displaystats" style={{marginTop: "5rem"}}>
        <div><label>Pending Volunteer Hours: </label><div className="hoursDisplayed" id="pvh">{pvh}</div></div>
        <div><label>Volunteer Hours Approved: </label><div className="hoursDisplayed">{vha}</div></div>
        <div><label>Volunteer Hours Redeemd: </label><div className="hoursDisplayed">{vhr}</div></div>
      </div>
      <div className="redeemContent">
        <h2>{text}</h2>
        <div>{elements}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Redeem;
