import './OauthDiscord.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import axios from "axios"
import Cookies from 'universal-cookie';
import React, { useState, useEffect } from 'react'

const DiscordOAuth =  "https://discord.com/api/oauth2/authorize?client_id=860285836683116565&redirect_uri=https%3A%2F%2Fbotsitivity.org%2Foauth&response_type=code&scope=identify%20email%20guilds"

function OauthDiscord() {
  const [ linkStatus, setLinkStatus ] = useState("Connecting to discord...")
  const [ subhead, setSubhead ] = useState("This might take a few second")

  useEffect(async()=>{

    const urlParams = new URLSearchParams(window.location.search);
    let discordCode = urlParams.get("code")

    if (!discordCode) window.location.replace(DiscordOAuth)

    let cookies = new Cookies()
    const session = cookies.get("session")

    console.log(discordCode)
    console.log(session)

    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/oauth`
    if (window.location.hostname=="localhost") postURL = `http://${window.location.host.split(":")[0]}:2000/oauth`
    let data = await axios.post(postURL, { session: session, oauthCode: discordCode })
    console.log(data.data)
    if (data.data.code==200){
      setLinkStatus("Success!")
      setSubhead("Successfully connected your discord account!")
    }else{
      setLinkStatus("Error!")
      setSubhead(data.data.message)
    }

  }, [])

  return (
    <div className="Link">
      <Menu/>
        <div className="oauthback">
          <h1>{linkStatus}</h1>
          <h2>{subhead}</h2>
        </div>
      <Footer/>
    </div>
  );
}

export default OauthDiscord;
