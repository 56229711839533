import './ForgotPassword.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import InputLine from "../../components/InputBox/InputBox.js"
import SubmitButton from "../../components/ButtonYellow/buttonYellow.js"
import axios from 'axios';
import React, { useState, useEffect } from 'react';

function PageNotFound() {

  const submit = async()=>{
    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/resetpassword`
    if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/resetpassword`
    axios.post(postURL, {email:document.getElementById("email").value})
    document.getElementById("submitBtn").innerHTML = "If this email exists in our database, an email with instructions will be sent to it."
  }

  const [buttonWidth, setButtonWidth]  = useState("250px")

  useEffect(async()=>{
    console.log("less")
    if (window.innerWidth<=900){
      setButtonWidth("150px")
    }
  },[])

  return (
    <div>
      <Menu />
      <div className="ForgotcreatedBackground">
        <div className="ForgotForgotPassowrdDiv">
          <InputLine label="Email:" marginL="0%" id="email"></InputLine>
          <div id="submitBtn">
            <SubmitButton content="Submit" Height="50px" Width={buttonWidth} func={submit}></SubmitButton>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
