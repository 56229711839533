import './Login.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import axios from "axios"
import InputBox from '../../components/InputBox/InputBox.js';
import React, {useEffect, useState} from 'react';
import YellowButton from '../../components/ButtonYellow/buttonYellow.js';
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';


function SignUp() {

  const [ marginLeft, setMarginLeft ] = useState("-10%")
  const [ buttonWidth, setButtonWidth ] = useState("250px")
  const [ buttonHeight, setButtonHeight ] = useState("100px")
  const [ mobileClass, setMobileClass ] = useState("bottomForm")

  useEffect(async()=>{
    if (window.innerWidth<=1000){
      setMarginLeft("15%")
      setMobileClass("topForm")
    }
    if (window.innerWidth < 600) {
      setButtonWidth("150px")
      setButtonHeight("75px")
    }
  })
  
  const checkInformation = async()=>{
    let warning = document.getElementById("warningMessage")
    let email = document.getElementById("email").value
    let password = document.getElementById("password").value

    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/login`
    if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/login`
    warning.innerHTML = "Logging in..."
    let login = await axios.post(postURL,{ email: email, password: password})
    if (login.data.code==403){
      window.location.replace("/suspended")
    }


    if (login.data.code==200){
      
      var cookies = new Cookies()
      await cookies.set("session", login.data.session, {domain: window.location.host})

      window.location.replace("/")

    }else{
      warning.innerHTML = login.data.message
    }
  }

  return (
    <div>
      <Menu/>
      <div className="OuterWrapper">
        <div className="centerWrap">
          <h1 className="signLogTitleText">Log in</h1>
          <div className={mobileClass}>
            <InputBox label="Email:" type="email" id="email" checkChangeSign={false}></InputBox>
          </div>
          <div className={mobileClass}>
            <InputBox label="Password:" type="password" id="password" checkChangeSign={false}></InputBox>
          </div>
          <div className="loginoptions">
            <Link className="forgotPassword underlineOrange" to="/forgotpassword">Forgot Password?</Link>
            <br></br>
            <Link className="forgotPassword underlineOrange" to="/signup">Don't have an account?</Link>
          </div>
          <div id="warningMessage">
          </div>
          <div className="proceedButtonContainer">
            <YellowButton content="Login" Height={buttonHeight} Width={buttonWidth} func={checkInformation}></YellowButton>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default SignUp;
