import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home/Home.js";
import PageNotFound from "./pages/404/404.js";
import SignUp from "./pages/Sign-up/Sign-up.js";
import Login from "./pages/Login/Login.js";
import UserHome from "./pages/UserHome/home.js";
import OauthDiscord from "./pages/OauthDiscord/OauthDiscord.js";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail.js";
import Logout from "./pages/logout/logout.js";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction.js";
import AccountCreated from "./pages/AccountCreation/AccountCreation.js";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword/ResetPassword.js";
import Statements from "./pages/Statements/Statements.js";
import Status from "./pages/Status/Status.js";
import Furtherexplanation from "./pages/Furtherexplanation/Furtherexplanation.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import Suspended from "./pages/Suspended/Suspended.js";
import Bylaws from "./pages/BylawsPage/BylawsPage.js";
import TeamPage from "./pages/TeamPage/teampage.js";
import Tasks from "./pages/Tasks/tasks.js";
import Reviews from "./pages/Reviews/reviews.js";
import Volunteers from "./pages/Volunteers/volunteers.js";
import Redeem from "./pages/Redeem/Redeem.js";
import MassEmail from "./pages/MassEmail/MassEmail.js";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/userhome" component={UserHome} />
        <Route exact path="/oauth" component={OauthDiscord} />
        <Route exact path="/verify/:id" component={VerifyEmail} />
        <Route exact path="/404" component={PageNotFound} />
        <Route exact path="/accountmade" component={AccountCreated} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/Statements" component={Statements} />
        <Route exact path="/Status" component={Status} />
        <Route
          exact
          path="/Furtherexplanation"
          component={Furtherexplanation}
        />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/bylaws" component={Bylaws} />
        <Route exact path="/reset/:id" component={ResetPassword} />
        <Route exact path="/suspended" component={Suspended} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/tasks" component={Tasks} />
        <Route exact path="/volunteers" component={Volunteers} />
        <Route exact path="/reviews" component={Reviews} />
        <Route exact path="/UnderConstruction" component={UnderConstruction} />
        <Route exact path="/Redeem" component={Redeem} />
        <Route exact path="/MassEmail" component={MassEmail} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

export default App;
