import './Suspended.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import { Link } from "react-router-dom"

function PageNotFound() {
  return (
    <div className="error">
      <Menu />
      <div className="background404">
        <div className="notFound">
          <h1 className="notFoundText">Account Suspended</h1>
          <h2 className="notFoundText">Contact a moderator for appeal</h2>
          <Link to="/">Click here to return to main page.</Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
