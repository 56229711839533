import React from "react";
import { Link } from "react-router-dom";
import './loading.css';

const Loading = ({id}) => {
    return (
        <div className="loadingCover" id={id}>
            <div className="dot-load">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="loadtext">
                Botsitivity Loading
            </div>
        </div>
    );
};

export default Loading;
