import './tasks.css';
import Menu from "../../components/Menu/Menu.js"
import Footer from "../../components/Footer/Footer.js"
import React, { useEffect, useState } from "react";
import YellowButton from "../../components/ButtonYellow/buttonYellow.js";
import Cookies from "universal-cookie";
import Loading from '../../components/Loading/loading';
import axios from 'axios';


function Tasks() {
    
    var UserTasks, tasks, allTasks, currentTaskID, col, previousResponse, sortBy="completion";

    const [listOfTasksNames, setListOfTasksNames] = useState([])
    const [displayTask, setDisplayTask] = useState(<div className="emptyTaskFont">Select a task to get started</div>)
    const [pvh, setpvh] = useState(0)
    const [vha, setvha] = useState(0)
    const [vhr, setvhr] = useState(0)
    const cookies = new Cookies()
    const session = cookies.get("session")

    useEffect(async ()=>{
        if (!session) window.location.replace("/404")

        let postURL1=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/userinfo`
        if (window.location.hostname=="localhost") postURL1 = `${window.location.protocol}//${window.location.hostname}:2000/userinfo`
        let fetchUser = await axios.post(postURL1,{ session: session })
        
        let postURL2=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/fetchtasks`
        if (window.location.hostname=="localhost") postURL2 = `${window.location.protocol}//${window.location.hostname}:2000/fetchtasks`
        let fetchedTasks = await axios.post(postURL2,{session:session})

        allTasks = fetchedTasks.data.payload
        
        const tasksNames = fetchedTasks.data.payload.map((task)=>{return {name: task.name, id: task.taskID, limit: task.limit, hours: task.hours } })

        UserTasks = fetchUser.data.payload.tasks

        const user = fetchUser.data.payload

        setpvh( Math.round(user.PendingVolunteerHours * 100) / 100 )
        setvha( Math.round(user.VolunteerHoursEarned * 100) / 100 )
        setvhr( Math.round(user.VolunteerHoursRedeemed * 100) / 100 )
        
        tasks = tasksNames

        col = document.getElementById("workspace").offsetWidth/15
        
        window.addEventListener('resize', ()=>{

            let input = document.getElementById("inputtask")
            if (input) input.cols = document.getElementById("workspace").offsetWidth/15

        })

        await renderTasksNames()

        document.getElementById("loader").classList.toggle("stopLoading")

    }, [])

    const renderTasksNames = async()=>{
        
        if (UserTasks.length!=0){

            let processedTasks = []
            let listOfTasks = []
            
            tasks.forEach((item)=>{

                let limit = item.limit
                if (limit == false){
                    limit = "∞"
                }

                let statusColor = "notDone"
                let priority = 0

                if (Number.isInteger(limit)){
                    if (UserTasks[item.id].count==limit){
                        statusColor = "done"
                    }
                }
                
                if (sortBy == "completion"){
                    if (Number.isInteger(limit)){
                        // priority = UserTasks[item.id].count/limit
                        if (priority>=1) return
                    }else{
                        priority = 0
                    }
                }

                listOfTasks.push(
                    {priority: priority,html:
                        <div id={item.id} className="taskName" onClick={()=> {selectTask(item.id)} }>
                            <div>{item.name}</div>
                            <div className={statusColor} id={`counter${item.id}`}>{UserTasks[item.id].count}/{limit}</div>
                            <div>{item.hours} hours each</div>
                        </div>
                    }
                )
                
            })

            listOfTasks.sort((a, b)=>{
                let ap = a.priority
                let bp = b.priority
                if (ap < bp) 
                {
                  return -1;
                }    
                else if (bp > ap)
                {
                  return 1;
                }   
                return 0;
            });
            

            listOfTasks.forEach((task)=>{
                processedTasks.push(task.html)
            })
    
            setListOfTasksNames(processedTasks)
        }

        return new Promise((res,rej)=>{
            res("done")
        })

    }
    
    const selectTask = (id)=>{
        document.getElementById(id).classList.toggle("selectedTask")
        if (currentTaskID) document.getElementById(currentTaskID).classList.toggle("selectedTask")
        currentTaskID = id
        showTask(allTasks[parseInt(id.replace("v",""))])
    }

    const submit = async()=>{
        let value = document.getElementById("inputtask").value
        if (value==previousResponse){
            document.getElementById("log").innerHTML = "Cannot submit the same response"
            return
        }
        previousResponse = value
        let postURL3=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/submittask`
        if (window.location.hostname=="localhost") postURL3 = `${window.location.protocol}//${window.location.hostname}:2000/submittask`
        let result = await axios.post(postURL3,{ session: session, response: value, taskID: currentTaskID })
        if (result.data.code!=200){
            document.getElementById("log").innerHTML = result.data.message
        }else{
            document.getElementById("success").innerHTML = result.data.message
            UserTasks[currentTaskID].count ++
            let limit = document.getElementById(`counter${currentTaskID}`).innerHTML.split("/")[1]
            document.getElementById(`counter${currentTaskID}`).innerHTML = `${UserTasks[currentTaskID].count}/${limit}`
            let currentHours = parseFloat(document.getElementById("pvh").innerHTML)
            let newValue = currentHours + allTasks[parseInt(currentTaskID.replace("v",""))].hours
            setpvh(newValue)
        }
        
    }

    const showTask = (task)=>{
        let text = task.description
        if (!text) text = task.prompt
        if (document.getElementById("success")) document.getElementById("success").innerHTML = ""
        if (document.getElementById("log")) document.getElementById("log").innerHTML = ""
        if (document.getElementById("inputtask")) document.getElementById("inputtask").value = ""
        setDisplayTask(
            <div className="currentTaskDisplayed">
                <h2>{text}</h2>
                <div>
                    <textarea name="paragraph_text" cols={col} rows="5" className="inputtask" id="inputtask"></textarea>
                </div>
                <YellowButton
                    content="Submit"
                    id="proceed"
                    Height={"50px"}
                    Width={"150px"}
                    func={submit}
                ></YellowButton>
                <div id="log"></div>
                <div id="success"></div>
            </div>
        )
    }

    return ( 
        <div>
            <Loading id="loader"/>
            <Menu/>
                <div className="displaystats">
                    <div><label>Pending Volunteer Hours: </label><div className="hoursDisplayed" id="pvh">{pvh}</div></div>
                    <div><label>Volunteer Hours Approved: </label><div className="hoursDisplayed">{vha}</div></div>
                    <div><label>Volunteer Hours Redeemd: </label><div className="hoursDisplayed">{vhr}</div></div>
                </div>
                <div className="taskshome">
                    <div className="displaytasks">{listOfTasksNames}</div>
                    <div className="dotasks" id="workspace">
                        {displayTask}
                    </div>
                </div>
            <Footer/>
        </div>
    );
}

export default Tasks;