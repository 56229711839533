import React from "react"
import "./buttonYellow.css"

const YellowButton = (props)=>{
    return(
        <div style={{height: (parseInt(props.Height.replace("px","").replace("%",""))+5).toString()+"px"}}>
            <button className="yellowBtn" style={{"height":props.Height,"width":props.Width, fontFamily:"'Nunito', sans-serif", color: "#334756"}} onClick={props.func} id={props.id}>{props.content}</button>
        </div>
    );
}
export default YellowButton