import './Home.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import HomeScreen from '../../components/HomeScreen/HomeScreen.js'
import Persaude from "../../components/persuade/persuade.js"
import FloatingArrow from '../../components/FloatingArrow/Floating';
import { useState } from 'react';

function Home() {
  const [width, setWidth] = useState(window.innerWidth)
  function isVisible(element) {
    let elementBox = element.getBoundingClientRect();
    let distanceFromTop = -450; 

    if(elementBox.top - window.innerHeight < distanceFromTop) {
        return true;
    } else {
        return false;
    }
  }

  function scanDocument() {
    let sectionList = document.querySelectorAll('.hidden');
    sectionList.forEach(function(section) {
        if(isVisible(section)) {
            section.classList.remove('hidden');
        };
    });
  }

  document.addEventListener("scroll", scanDocument)

  return (
    <div className="Home">
      <Menu />
      <HomeScreen screen={width}/>
      <FloatingArrow></FloatingArrow>
      <Persaude></Persaude>
      <Footer/>
    </div>
  );
}

export default Home;
