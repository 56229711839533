import './VerifyEmail.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Content1 from '../../components/content1/content1.js'
import React, { useState, useEffect} from 'react'
import axios from 'axios';
import { parse } from 'path';


function EventPage() {

  const [ verifyStatus, setVerifyStatus ] = useState("Verifying email...")

  useEffect(async()=>{
    let verifyCode = document.URL.split("verify/")[1]

    console.log(verifyCode)

    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/verify/${verifyCode}`
    if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/verify/${verifyCode}`
    let data = await axios.post(postURL)

    if (data.data.code==200){
      setVerifyStatus("Successfully verified!")
    }else{
      setVerifyStatus("Failed to verified!")
    }
  }, [])

  return (
    <div>
      <Menu />
      <div className="createdBackground">
        <div className="notificationAccountDiv">
          <p>
            {verifyStatus}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EventPage;
