import './logout.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Cookies from "universal-cookie";
import React, { useEffect } from 'react';
import axios from "axios"

function PageNotFound() {

  useEffect(async()=>{
    const cook = new Cookies()
    const session = cook.get("session")
    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/logout`
    if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/logout`
    await axios.post(postURL,{ session: session})

    let cookies = window.document.cookie.split(";")
    for (let part of cookies){
        cook.remove(part)
    }
    window.location.replace(`http://${window.location.host}`)
  })

  return (
    <div>
      <Menu />
      <div className="logoutBackground">
        <div className="logoutInnerBackground">
          <h2>Logging out...</h2>
          <h2>This will take some time...</h2>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
