import React from "react";
import "./Bylaws.css"

const Bylaws = (props)=> {
    return(
        <div class="b8">
                <div>
                        <p class="b1 b2"><span class="b9"></span></p>
                </div>
                <p class="b4"><span class="b0">CORPORATE BYLAWS OF</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">BOTSITIVITY INC</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">INCORPORATED IN THE STATE OF NEW YORK</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE I - NAME</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">1.01 Name </span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The name of this corporation shall be BOTSITIVITY INC. The business of the
                        corporation may be conducted as BOTSITIVITY.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE II - PURPOSES AND NAMES</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">2.01 Purpose</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSITIVITY is a non-profit corporation and shall be operated exclusively for
                        educational and charitable purposes within the meaning of Section 501 (c)(3) of the Internal Revenue Code of
                        1986, or the corresponding section of any future Federal tax code.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">BOTSITIVITY&rsquo;s purpose </span><span class="b5">is to raise the support for
                        mental health through the usage of machine learning.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">2.02 Powers</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The corporation shall have the power, directly or indirectly, alone or in conjunction
                        or cooperation with others, to do any and all lawful acts which may be necessary or convenient to affect the
                        charitable purposes, for which the corporation is organized, and to aid or assist other organizations or
                        persons whose activities further accomplish, foster, or attain such purposes. The powers of the corporation
                        may include, but not be limited to, the acceptance of contributions from the public and private sectors,
                        whether financial or in-kind contributions.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">2.03 Nonprofit Status and Exempt Activities Limitation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">(a)Nonprofit Legal Status </span><span class="b5">BOTSITIVITY</span><span
                        class="b0">&nbsp;is a New York non-profit public benefit corporation, recognized as tax exempt under Section
                        501(c)(3) of the United States Internal Revenue Code.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b)Exempt Activities Limitation. Notwithstanding any other provision of these Bylaws,
                        no director, officer, employee, member, or representative of this corporation shall take any action or carry
                        on any activity by or on behalf of the corporation not permitted to be taken or carried on by an
                        organization exempt under Section 501(c)(3) of the Internal Revenue Code as it now exists or may be amended,
                        or by any organization contributions to which are deductible under Section 170(c)(2) of such Code and
                        Regulations as it now exists or may be amended. No part of the net earnings of the corporation shall inure
                        to the benefit or be distributable to any director, officer, member, or other private person, except that
                        the corporation shall be authorized and empowered to pay reasonable compensation for services rendered and
                        to make payments and distributions in furtherance of the purposes set forth in the Articles of Incorporation
                        and these Bylaws.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c)Distribution Upon Dissolution. Upon termination or dissolution of the BOTSITIVITY,
                        any assets lawfully available for distribution shall be distributed to one (1) or more qualifying
                        organizations described in Section 501(c)(3) of the 1986 Internal Revenue Code (or described in any
                        corresponding provision of any successor statute) which organization or organizations have a charitable
                        purpose which, at least generally, includes a purpose similar to the terminating or dissolving
                        corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The organization to receive the assets of the BOTSITIVITY hereunder shall be selected
                        in the discretion of a majority of the managing body of the corporation, and if its members cannot so agree,
                        then the recipient organization shall be selected pursuant to a verified petition in equity filed in a court
                        of proper jurisdiction against the BOTSITIVITY, by one (1) or more of its managing body which verified
                        petition shall contain such statements as reasonably indicate the applicability of this section. The court
                        upon a finding that this section is applicable shall select the qualifying organization or organizations to
                        receive the assets to be distributed, giving preference if practicable to organizations located within the
                        State of New York.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">In the event that the court shall find that this section is applicable but that there
                        is no qualifying organization known to it which has a charitable purpose, which, at least generally,
                        includes a purpose similar to the BOTSITIVITY, then the court shall direct the distribution of its assets
                        lawfully available for distribution to the Treasurer of the State of New York to be added to the general
                        fund.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE III - MEMBERSHIP</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">3.01 No Membership Classes</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The corporation shall have no members who have any right to vote or title or interest
                        in or to the corporation, its properties and franchises.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">3.02 Non-Voting Affiliates</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board of directors may approve classes of non-voting affiliates with rights,
                        privileges, and obligations established by the board. Affiliates may be individuals, businesses, and other
                        organizations that seek to support the mission of the corporation. The board, a designated committee of the
                        board, or any duly elected officer in accordance with board policy, shall have authority to admit any
                        individual or organization as an affiliate, to recognize representatives of affiliates, and to make
                        determinations as to affiliates&#39; rights, privileges, and obligations. At no time shall affiliate
                        information be shared with or sold to other organizations or groups without the affiliate&#39;s consent. At
                        the discretion of the board of directors, affiliates may be given endorsement, recognition and media
                        coverage at fundraising activities, clinics, other events or at the corporation website. Affiliates have no
                        voting rights, and are not members of the corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">3.03 Dues</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Any dues for affiliates shall be determined by the board of directors.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE IV - BOARD OF DIRECTORS</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.01 Number of Directors</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSTIVITY shall have a board of directors consisting of at least 4 and no more than
                        15 directors. Within these limits, the board may increase or decrease the number of directors serving on the
                        board, including for the purpose of staggering the terms of directors.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.02 Powers</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">All corporate powers shall be exercised by or under the authority of the board and
                        the affairs of the BOTSITIVITY shall be managed under the direction of the board, except as otherwise
                        provided by law.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.03 Terms</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) All directors shall be elected to serve a one-year term, however the term may be
                        extended until a successor has been elected.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) Director terms shall be staggered so that approximately half the number of
                        directors will end their terms in any given year.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) Directors may serve terms in succession.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(d) The term of office shall be considered to begin January 1 and end December 31 of
                        the second year in office, unless the term is extended until such time as a successor has been
                        elected.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.04 Qualifications and Election of Directors</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">In order to be eligible to serve as a director on the board of directors, the
                        individual must be 13 years of age and an affiliate within affiliate classifications created by the board of
                        directors. Directors may be elected at any board meeting by the majority vote of the existing board of
                        directors. The election of directors to replace those who have fulfilled their term of office shall take
                        place in January of each year.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.05 Vacancies</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board of directors may fill vacancies due to the expiration of a director&#39;s
                        term of office, resignation, death, or removal of a director or may appoint new directors to fill a
                        previously unfilled board position, subject to the maximum number of directors under these Bylaws.</span>
                </p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) Unexpected Vacancies. Vacancies in the board of directors due to resignation,
                        death, or removal shall be filled by the board members for the balance of the term of the director being
                        replaced.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.06 Removal of Directors</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">A director may be removed by both CEO&rsquo;s, if: </span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) the director is absent and unexcused from two or more meetings of the board of
                        directors in a twelve month period. The board president is empowered to excuse directors from attendance for
                        a reason deemed adequate by the board president. The president shall not have the power to</span></p>
                <p class="b1"><span class="b0">excuse him/herself from the board meeting attendance and in that case, the board vice
                        president shall excuse the president. Or:</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) for cause or no cause, if before any meeting of the board members at which a vote
                        on removal will be made the director in question is given electronic or written notification of the
                        board&#39;s intention to discuss her/his case and is given the opportunity to be heard at a meeting of the
                        board.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.07 Board of Directors Meetings.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) Regular Meetings. The board of directors shall have a minimum of four (4) regular
                        meetings each calendar year at times and places fixed by the board. Board meetings shall be held upon four
                        (4) days notice by first-class mail, electronic mail, or facsimile transmission or forty-eight (48) hours
                        notice delivered personally or by telephone. If sent by mail, facsimile transmission, or electronic mail,
                        the notice shall be deemed to be delivered upon its deposit in the mail or transmission system. Notice of
                        meetings shall specify the place, day, and hour of meeting. The purpose of the meeting need not be
                        specified.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) Special Meetings. Special meetings of the board may be called by the president,
                        vice president, secretary, treasurer, or any two (2) other directors of the board of directors. A special
                        meeting must be preceded by at least 2 days of notice to each director of the date, time, and place, but not
                        the purpose, of the meeting.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) Waiver of Notice. Any director may waive notice of any meeting, in accordance
                        with New York law.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.08 Manner of Acting.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) Quorum. A majority of the directors in office immediately before a meeting shall
                        constitute a quorum for the transaction of business at that meeting of the board. No business shall be
                        considered by the board at any meeting at which a quorum is not present.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) Majority Vote. Except as otherwise required by law or by the articles of
                        incorporation, the act of the majority of the directors present at a meeting at which a quorum is present
                        shall be the act of the board.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(C) Hung Board Decisions. On the occasion that directors of the board are unable to
                        make a decision based on a tied number of votes, the president or treasurer in the order of presence shall
                        have the power to swing the vote based on his/her discretion.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(d) Participation. Except as required otherwise by law, the Articles of
                        Incorporation, or these Bylaws, directors may participate in a regular or special meeting through the use of
                        any means of communication by which all directors participating may simultaneously hear each other during
                        the meeting, including in person, internet video meeting or by telephonic conference call.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.09 Compensation for Board Members Services</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Directors shall receive no compensation for carrying out their duties as directors.
                        The board may adopt policies providing for reasonable reimbursement of directors for expenses incurred in
                        conjunction with carrying out board responsibilities, such as travel expenses to attend board
                        meetings.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">4.10 Compensation for Professional Services by Directors</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Directors are not restricted from being remunerated for professional services
                        provided to the corporation. Such remuneration shall be reasonable and fair to the corporation and must be
                        reviewed and approved in accordance with the board Conflict of Interest policy and state law.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE V - COMMITTEES </span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">5.01 Committees</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board of directors may, by the resolution adopted by a majority of the directors
                        then in office, designate one or more committees, each consisting of two or more directors, to serve at the
                        pleasure of the board. Any committee, to the extent provided in the resolution of the board, shall have all
                        the authority of the board, except that no committee, regardless of board resolution, may:</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) take any final action on matters which also requires board members&#39;</span>
                </p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">approval or approval of a majority of all members;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) fill vacancies on the board of directors of in any committee which has the
                        authority of the board;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) amend or repeal Bylaws or adopt new Bylaws;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(d) amend or repeal any resolution of the board of directors which by its express
                        terms is not so amendable or repealable;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(e) appoint any other committees of the board of directors of the members of</span>
                </p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">these committees;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(f) expend corporate funds to support a nominee for director; or</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(g) approve any transaction;</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(i) to which the corporation is a party and one or more directors have a material
                        financial interest; or</span></p>
                <p class="b1 b2 b3"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(ii) between the corporation and one or more of its directors or between the
                        corporation or any person in which one or more of its directors have a material financial interest.</span>
                </p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">5.02 Meetings and Action of Committees</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Meetings and action of the committees shall be governed by and held and taken in
                        accordance with, the provisions of Article IV of these Bylaws concerning meetings of the directors, with
                        such changes in the context of those Bylaws as are necessary to substitute the committee and its members for
                        the board of directors and its members, except that the time for regular meetings of committees may be
                        determined either by resolution of the board of directors or by resolution of the committee. Special
                        meetings of the committee may also be called by resolution of the board of directors. Notice of special
                        meetings of committees shall also be given to any and all alternate members, who shall have the right to
                        attend all meetings of the committee. Minutes shall be kept of each meeting of any committee and shall be
                        filed with the corporate records. The board of directors may adopt rules for the governing of the committee
                        not inconsistent with the provision of these Bylaws.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">5.03 Informal Action By The Board of Directors</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Any action required or permitted to be taken by the board of directors at a meeting
                        may be taken without a meeting if consent in writing, setting forth the action so taken, shall be agreed by
                        the consensus of a quorum. For purposes of this section an e-mail transmission from an email address on
                        record constitutes valid writing. The intent of this provision is to allow the board of directors to use
                        email to approve actions, as long as a quorum of board members gives consent.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE VI - OFFICERS</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.01 Board Officers</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The officers of the corporation shall be a board president, vice-president,
                        secretary, and treasurer, all of whom shall be chosen by, and serve at the pleasure of, the board of
                        directors. Each board officer shall have the authority and shall perform the duties set forth in these
                        Bylaws or by resolution of the board or by direction of an officer authorized by the board to prescribe the
                        duties and authority of other officers. The board may also appoint additional vice-presidents and such other
                        officers as it deems expedient for the proper conduct of the business of the corporation, each of whom shall
                        have such authority and shall perform such duties as the board of directors may determine. One person may
                        hold two or more board offices, but no board officer may act in more than one capacity where action of two
                        or more officers is required.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.02 Term of Office</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Each officer shall serve a one-year term of office and may not serve more than three
                        (3) consecutive terms of office. Unless unanimously elected by the board at the end of his/her three (3)
                        year terms or to fill a vacancy in an officer position, each board officer&#39;s term of office shall begin
                        upon the adjournment of the board meeting at which elected and shall end up the adjournment of the board
                        meeting during which a successor is elected.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.03 Removal and Resignation</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board of directors may remove an officer at any time, with or without cause. Any
                        officer may resign at any time by giving written notice to the corporation without prejudice to the rights,
                        if any, of the corporation under any contract to which the officer is a party. Any resignation shall take
                        effect at the date of the receipt of the notice or at any later time specified in the notice, unless
                        otherwise specified in the notice. The acceptance of the resignation shall not be necessary to make it
                        effective.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.04 Board President</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board president shall be the chief volunteer officer of the corporation. The
                        board president shall lead the board of directors in performing its duties and responsibilities, including,
                        if present, presiding at all meetings of the board of directors, and shall perform all other duties incident
                        to the office or properly required by the board of directors.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.05 Vice President</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">In the absence or disability of the board president, the ranking vice-president or
                        vice-president designated by the board of directors shall perform the duties of the board president. When so
                        acting, the vice-president shall have all the powers of and be subject to all the restrictions upon the
                        board president. The vice-president shall have such other powers and perform such other duties prescribed
                        for them by the board of directors or the board president. The vice president shall normally accede to the
                        office of board president upon the completion of the board president&#39;s term of office.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.06 Secretary</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The secretary shall keep or cause to be kept a book of minutes of all meetings and
                        actions of directors and committees of directors. The minutes of each meeting shall state the time and place
                        that it was held and such other information as shall be necessary to determine the actions taken and whether
                        the meeting was held in accordance with the law and these Bylaws. The secretary shall cause notice to be
                        given of all meetings of directors and committees as required by the Bylaws. The secretary shall have such
                        other powers and perform such other duties as may be prescribed by the board of directors or the board
                        president. The secretary may appoint, with approval of the board, a director to assist in performance of all
                        or part of the duties of the secretary.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.07 Treasurer</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The treasurer shall be the lead director for oversight of the financial condition and
                        affairs of the corporation. The treasurer shall oversee and keep the board informed of the financial
                        condition of the corporation and of audit or financial review results. In conjunction with other directors
                        or officers, the treasurer shall oversee budget preparation and shall ensure that appropriate financial
                        reports, including an account of major transactions and the financial condition of the corporation, are made
                        available to the board of directors on a timely basis or as may be required by the board of directors. The
                        treasurer shall perform all duties properly required by the board of directors or the board president. The
                        treasurer may appoint, with approval of the board, a qualified fiscal agent or member of the staff to assist
                        in performance of all or part of the duties of the treasurer.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">6.08 Non-Director Officers</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board of directors may designate additional officer positions of the corporation
                        and may appoint and assign duties to other non-director officers of the corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE VII - CONTRACTS, CHECKS, LOANS, INDEMNIFICATION AND RELATED MATTERS</span>
                </p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">7.01 Contracts and other Writings</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Except as otherwise provided by resolution of the board or board policy, all
                        contracts, deeds, leases, mortgages, grants, and other agreements of the corporation shall be executed on
                        its behalf by the treasurer or other persons to whom the corporation has delegated authority to execute such
                        documents in accordance with policies approved by the board.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">7.02 Checks, Drafts</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">All checks, drafts, or other orders for payment of money, notes, or other evidence of
                        indebtedness issued in the name of the corporation, shall be signed by such officer or officers, agent or
                        agents, of the corporation and in such manner as shall from time to time be determined by resolution of the
                        board.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">7.03 Deposits</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">All funds of the corporation not otherwise employed shall be deposited from time to
                        time to the credit of the corporation in such banks, trust companies, or other depository as the board or a
                        designated committee of the board may select.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">7.04 Loans</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">No loans shall be contracted on behalf of the corporation and no evidence of
                        indebtedness shall be issued in its name unless authorized by resolution of the board. Such authority may be
                        general or confined to specific instances.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">7.05 Indemnification</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) Mandatory Indemnification. The corporation shall indemnify a director or former
                        director, who was wholly successful, on the merits or otherwise, in the defense of any proceeding to which
                        he or she was a party because he or she is or was a director of the corporation against reasonable expenses
                        incurred by him or her in connection with the proceedings.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) Permissible Indemnification. The corporation shall indemnify a director or former
                        director made a party to a proceeding because he or she is or was a director of the corporation, against
                        liability incurred in the proceeding, if the determination to indemnify him or her has been made in the
                        manner prescribed by the law and payment has been authorized in the manner prescribed by law.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) Advance for Expenses. Expenses incurred in defending a civil or criminal action,
                        suit or proceeding may be paid by the corporation in advance of the final disposition of such action, suit
                        or proceeding, as authorized by the board of directors in the specific case, upon receipt of (I) a written
                        affirmation from the director, officer, employee or agent of his or her good faith belief that he or she is
                        entitled to indemnification as authorized in this article, and (II) an undertaking by or on behalf of the
                        director, officer, employee or agent to repay such amount, unless it shall ultimately be determined that he
                        or she is entitled to be indemnified by the corporation in these Bylaws.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(d) Indemnification of Officers, Agents and Employees. An officer of the corporation
                        who is not a director is entitled to mandatory indemnification under this article to the same extent as a
                        director. The corporation may also indemnify and advance expenses to an employee or agent of the corporation
                        who is not a director, consistent with New York Law and public policy, provided that such indemnification,
                        and the scope of such indemnification, is set forth by the general or specific action of the board or by
                        contract.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE VIII - MISCELLANEOUS</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">8.01 Books and Records</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The corporation shall keep correct and complete books and records of account and
                        shall keep minutes of the proceedings of all meetings of its board of directors, a record of all actions
                        taken by board of directors without a meeting, and a record of all actions taken by committees of the board.
                        In addition, the corporation shall keep a copy of the corporation&#39;s Articles of Incorporation and Bylaws
                        as amended to date.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">8.02 Fiscal Year</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The fiscal year of the corporation shall be from January 1 to December 31 of each
                        year.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">8.03 Conflict of Interest</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board shall adopt and periodically review a conflict of interest policy to
                        protect the corporation&#39;s interest when it is contemplating any transaction or arrangement which may
                        benefit any director, officer, employee, affiliate, or member of a committee with board-delegated
                        powers.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">8:04 Nondiscrimination Policy</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">The officers, directors, committee members, employees, and persons served by this
                        corporation shall be selected entirely on a nondiscriminatory basis with respect to age, sex, race,
                        religion, national origin, and sexual orientation. It is the policy of </span><span
                        class="b5">BOTSITIVITY</span><span class="b0">&nbsp;not to discriminate on the basis of race, creed,
                        ancestry, marital status, gender, sexual orientation, age, physical disability, veteran&#39;s status,
                        political service or affiliation, color, religion, or national origin.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">8.05 Bylaw Amendment</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">These Bylaws may be amended, altered, repealed, or restated by a vote of the majority
                        of the board of directors then in office at a meeting of the Board, provided, however,</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) that no amendment shall be made to these Bylaws which would cause the corporation
                        to cease to qualify as an exempt corporation under Section 501 (c) (3) of the Internal Revenue Code of 1986,
                        or the corresponding section of any future Federal tax code; and,</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) that an amendment does not affect the voting rights of directors. An amendment
                        that does affect the voting rights of directors further requires ratification by a two-thirds vote of a
                        quorum of directors at a Board meeting.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) that all amendments be consistent with the Articles of Incorporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE IX - COUNTERTERRORISM AND DUE DILIGENCE POLICY</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">In furtherance of its exemption by contributions to other organizations, domestic or
                        foreign, BOTSITIVITY shall stipulate how the funds will be used and shall require the recipient to provide
                        the corporation with detailed records and financial proof of how the funds were utilized.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Although adherence and compliance with the US Department of the Treasury&#39;s
                        publication the &quot;Voluntary Best Practice for US. Based Charities&quot; is not mandatory, BOTSITIVITY
                        willfully and voluntarily recognizes and puts to practice these guidelines and suggestions to reduce,
                        develop, re-evaluate and strengthen a risk-based approach to guard against the threat of diversion of
                        charitable funds or exploitation of charitable activity by terrorist organizations and their support
                        networks.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSITVITY shall also comply and put into practice the federal guidelines,
                        suggestion, laws and limitation set forth by pre existing U.S. legal requirements related to combating
                        terrorist financing, which include, but are not limited to, various sanctions programs administered by the
                        Office of Foreign Assets Control (OFAC) in regard to its foreign activities.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE X - DOCUMENT RETENTION POLICY</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">10.01 Purpose</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The purpose of this document retention policy is establishing standards for document
                        integrity, retention, and destruction and to promote the proper treatment of BOTSITVITY records.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">10.02 Policy</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Section 1. General Guidelines. Records should not be kept if they are no longer
                        needed for the operation of the business or required by law. Unnecessary records should be eliminated from
                        the files. The cost of maintaining records is an expense which can grow unreasonably if good housekeeping is
                        not performed. A mass of records also makes it more difficult to find pertinent records.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">From time to time, </span><span class="b5">BOTSITIVITY</span><span
                        class="b0">&nbsp;may establish retention or destruction policies or schedules for specific categories of
                        records in order to ensure legal compliance, and also to accomplish other objectives, such as preserving
                        intellectual property and cost management. Several categories of documents that warrant special
                        consideration are identified below. While minimum retention periods are established, the retention of the
                        documents identified below and of documents not included in the identified categories should be determined
                        primarily by the application of the general guidelines affecting document retention, as well as the
                        exception for litigation relevant documents and any other pertinent factors.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Section 2. Exception for Litigation Relevant Documents. BOTSITIVITY expects all
                        officers, directors, and employees to comply fully with any published records retention or destruction
                        policies and schedules, provided that all officers, directors, and employees should note the following
                        general exception to any stated destruction schedule: If you believe, or BOTSITIVITY informs you, that
                        corporate records are relevant to litigation, or potential litigation (i.e. a dispute that could result in
                        litigation), then you must preserve those records until it is determined that the records are no longer
                        needed. That exception supersedes any previously or subsequently established destruction schedule for those
                        records.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Section 3. Minimum Retention Periods for Specific Categories</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(a) Corporate Documents. Corporate records include the corporation&#39;s Articles of
                        Incorporation, By-Laws and IRS Form 1023 and Application for Exemption. Corporate records should be retained
                        permanently. IRS regulations require that the Form 1023 be available for public inspection upon
                        request.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(b) Tax Records. Tax records include, but may not be limited to, documents concerning
                        payroll, expenses, proof of contributions made by donors, accounting procedures, and other documents
                        concerning the corporation&#39;s revenues. Tax records should be retained for at least seven years from the
                        date of filing the applicable return.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(c) Employment Records/Personnel Records. State and federal statutes require the
                        corporation to keep certain recruitment, employment and personnel information. The corporation should also
                        keep personnel files that reflect performance reviews and any complaints brought against the corporation or
                        individual employees under applicable state and federal statutes. The corporation should also keep in the
                        employee&#39;s personnel file all final memoranda and correspondence reflecting performance reviews and
                        actions taken by or against personnel. Employment applications should be retained for three years.
                        Retirement and pension records should be kept permanently. Other employment and personnel records should be
                        retained for seven years.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(d) Board and Board Committee Materials. Meeting minutes should be retained in
                        perpetuity in the corporation&#39;s minute book. A clean copy of all other Board and Board Committee
                        materials should be kept for no less than three years by the corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(e) Press Releases/Public Filings. The corporation should retain permanent copies of
                        all press releases and publicly filed documents under the theory that the corporation should have its own
                        copy to test the accuracy of any document a member of the public can theoretically produce against the
                        corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(f) Legal Files. Legal counsel should be consulted to determine the retention period
                        of particular documents, but legal documents should generally be maintained for a period of ten
                        years.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(g) Marketing and Sales Documents. The corporation should keep final copies of
                        marketing and sales documents for the same period of time it keeps other corporate files, generally three
                        years. An exception to the three-year policy may be sales invoices, contracts, leases, licenses, and other
                        legal documentation. These documents should be kept for at least three years beyond the life of the
                        agreement.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(h) Development/Intellectual Property and Trade Secrets. Development documents are
                        often subject to intellectual property protection in their final form (e.g., patents and copyrights). The
                        documents detailing the development. process are often also of value to the corporation and are protected as
                        a trade secret where the corporation:</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(i) derives independent economic value from the secrecy of the information; and
                        </span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(ii) has taken affirmative steps to keep the information confidential.</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">The corporation should keep all documents designated as containing trade secret
                        information for at least the life of the trade secret.</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(i) Contracts. Final, execution copies of all contracts entered into by the
                        corporation should be retained. The corporation should retain copies of the final contracts for at least
                        three years beyond the life of the agreement, and longer in the case of publicly filed contracts.</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(j) Correspondence. Unless correspondence falls under another category listed
                        elsewhere in this policy, correspondence should generally be saved for two years.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(k) Banking and Accounting. Accounts payable ledgers and schedules should be kept for
                        seven years. Bank reconciliations, bank statements, deposit slips and checks (unless for important payments
                        and purchases) should be kept for three years. Any inventories of products, materials, and supplies and any
                        invoices should be kept for seven years.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(1) Insurance. Expired insurance policies, insurance records, accident reports,
                        claims, etc. should be kept permanently.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">(m) Audit Records. External audit reports should be kept permanently. Internal audit
                        reports should be kept for three years.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">Electronic Mail. E-mail that needs to be saved should be either: </span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(i) printed in hard copy and kept in the appropriate file; or</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1 b3"><span class="b0">(ii) downloaded to a computer file and kept electronically or on disk as a
                        separate file. The retention period depends upon the subject matter of the email, as covered elsewhere in
                        this policy.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE XI - TRANSPARENCY AND ACCOUNTABILITY</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">11.01 Purpose</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">By making full and accurate information about its mission, activities, finances, and
                        governance publicly available, BOTSITIVITY practices and encourages transparency and accountability to the
                        general public. This policy will:</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_qf8cunf9lmoq-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">indicate which documents and materials produced by the
                                corporation are presumptively open to staff and/or the public</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_dhzuje2qhdxt-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">indicate which documents and materials produced by the
                                corporation are presumptively closed to staff and/or the public </span></li>
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">specify the procedures whereby the open/closed status of
                                documents and materials can be altered.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The details of this policy are as follow:</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_5j0xkdvqwklg-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">Financial and IRS documents (The form 1023 and the form
                                990)</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSITIVITY shall provide its Internal Revenue forms 990, 990-T, 1023 and 5227,
                        bylaws, conflict of interest policy, and financial statements to the general public for inspection free of
                        charge.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Means and Conditions of Disclosure</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSITIVITY shall make &quot;Widely Available&quot; the aforementioned documents on
                        its internet website: BOTSITIVITY to be viewed and inspected by the general public.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The documents shall be posted in a format that allows an individual using the
                        Internet to access, download, view and print them in a manner that exactly reproduces the image of the
                        original document filed with the IRS (except information exempt from public disclosure requirements, such as
                        contributor lists).</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The website shall clearly inform readers that the document is available and provide
                        instructions for downloading it.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_b4zbfpj40z8j-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">BOTSITIVITY shall not charge a fee for downloading the
                                information. Documents shall not be posted in a format that would require special computer hardware or
                                software (other than software readily available to the public free of charge). </span></li>
                </ul>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_b4zbfpj40z8j-0">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">BOTSITIVITY shall inform anyone requesting the information
                                where this information can be found, including the web address. This information must be provided
                                immediately for in-person requests and within 7 days for mailed requests. </span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">11.04 IRS Annual Information Returns (Form 990)</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">BOTSITIVITY shall submit the Form 990 to its board of directors prior to the filing
                        of the Form 990. While neither the approval of the Form 990 </span><span class="b5">or a</span><span
                        class="b0">&nbsp;review of the 990 is required under Federal law, the corporation&#39;s Form 990 shall be
                        submitted to each member of the board of director&#39;s via (hard copy or email) at least 10 days before the
                        Form 990 is filed with the IRS.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">11.05 Board</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_6iz0wx62dx5h-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">All board deliberations shall be open to the public except
                                where the board passes a motion to make any specific portion confidential.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_jz31kkm24p0b-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">All board minutes shall be open to the public once accepted by
                                the board, except where the board passes a motion to make any specific portion confidential.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_r9i76zj94d0x-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">All papers and materials considered by the board shall be open
                                to the public following the meeting at which they are considered, except where the board passes a motion
                                to make any specific paper or material confidential.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">11.06 Staff Records</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_qcycb3pl8tkj-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">All staff records shall be available for consultation by the
                                staff member concerned or by their legal representatives. </span></li>
                </ul>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_qcycb3pl8tkj-0">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">No staff records shall be made available to any person outside
                                the corporation except the authorized governmental agencies. </span></li>
                </ul>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_qcycb3pl8tkj-0">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">Within the corporation, staff records shall be made available
                                only to those persons with managerial or personnel responsibilities for that staff member, except
                                that</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_965ch4yh5iho-0 start">
                        <li class="b1 b10 b7 li-bullet-0"><span class="b0">Staff records shall be made available to the board when
                                requested.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">11.07 Donor Records</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_oywbd7f7m72b-0 start">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">All donor records shall be available for consultation by the
                                members and donors concerned or by their legal representatives </span></li>
                </ul>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_oywbd7f7m72b-0">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">No donor records shall be made available to any other person
                                outside the corporation except the authorized governmental agencies. </span></li>
                </ul>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_oywbd7f7m72b-0">
                        <li class="b1 b3 b7 li-bullet-0"><span class="b0">Within the corporation, donor records shall be made available
                                only to those persons with managerial or personnel responsibilities for dealing with those donors,
                                except that ;</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <ul class="b6 lst-kix_pbwochf5nx0w-0 start">
                        <li class="b1 b7 b10 li-bullet-0"><span class="b0">donor records shall be made available to the board when
                                requested.</span></li>
                </ul>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE XII CODES OF ETHICS AND WHISTLEBLOWER POLICY</span></p>
                <p class="b4 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.01 Purpose</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">BOTSITIVITY requires and encourages directors, officers and employees to observe and
                        practice high standards of business and personal ethics in the conduct of their duties and responsibilities.
                        The employees and representatives of the corporation must practice honesty and integrity in fulfilling their
                        responsibilities and comply with all applicable laws and regulations. It is the intent of </span><span
                        class="b5">BOTSITIVITY</span><span class="b0">&nbsp;to adhere to all laws and regulations that apply to the
                        corporation and the underlying purpose of this policy is to support the corporation&#39;s goal of legal
                        compliance. The support of all corporate staff is necessary to achieve compliance with various laws and
                        regulations.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.02 Reporting Violations</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">If any director, officer, staff or employee reasonably believes that some policy,
                        practice, or activity of BOTSITIVITY is in violation of law, a written complaint must be filed by that
                        person with the vice president or the board president.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.03 Acting in Good Faith</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Anyone filing a complaint concerning a violation or suspected violation must be
                        acting in good faith and have reasonable grounds for believing the information disclosed indicates a
                        violation. Any allegations that prove not to be substantiated and which prove to have been made maliciously
                        or knowingly to be false shall be subject to civil and criminal review.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.04 Retaliation</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">Said person is protected from retaliation only if she/he brings the alleged unlawful
                        activity, policy, or practice to the attention of </span><span class="b5">BOTSITIVITY</span><span
                        class="b5">&nbsp;and provides the </span><span class="b5">BOTSITIVITY</span><span class="b0">&nbsp;with a
                        reasonable opportunity to investigate and correct the alleged unlawful activity. The protection described
                        below is only available to individuals that comply with this requirement.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">BOTSITIVITY shall not retaliate against any director, officer, staff or employee who
                        in good faith, has made a protest or raised a complaint against some practice of </span><span
                        class="b5">BOTSITIVITY</span><span class="b5">&nbsp;or of another individual or entity with whom
                        </span><span class="b5">BOTSITIVITY</span><span class="b0">&nbsp;has a business relationship, on the basis of a
                        reasonable belief that the practice is in violation of law, or a clear mandate of public policy.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">BOTSITIVITY shall not retaliate against any director, officer, staff or employee who
                        disclose or threaten to disclose to a supervisor or a public body, any activity, policy, or practice of
                        BOTSITIVITY that the individual reasonably believes is in violation of a law, or a rule, or regulation
                        mandated pursuant to law or is in violation of a clear mandate of public policy concerning the health,
                        safety, welfare, or protection of the environment.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.05 Confidentiality</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Violations or suspected violations may be submitted on a confidential basis by the
                        complainant or may be submitted anonymously. Reports of violations or suspected violations shall be kept
                        confidential to the extent possible, consistent with the need to conduct an adequate investigation.</span>
                </p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">12.06 Handling of Reported Violations</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">The board president or vice president shall notify the sender and acknowledge receipt
                        of the reported violation or suspected violation within five business days. All reports shall be promptly
                        investigated by the board and its appointed committee and appropriate corrective action shall be taken if
                        warranted by the investigation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">This policy shall be made available to all directors, officers, staff or employees
                        and they shall have the opportunity to ask questions about the policy.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b4"><span class="b0">ARTICLE XIII - AMENDMENTS OF ARTICLES OF INCORPORATION</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">13.01 Amendment</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Any amendment to the Articles of Incorporation may be adopted by approval of
                        two-thirds (2/3) of the board of directors.</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">CERTIFICATE OF ADOPTION OF BYLAWS</span></p>
                <p class="b1 b3 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b5">I do hereby certify that the above stated Bylaws of </span><span
                        class="b5">BOTSITIVITY</span><span class="b0">&nbsp;were approved by the BOTSITIVITY board of directors on
                        07/25/2021 and constitute a complete copy of the Bylaws of the corporation.</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Enyu Zhu, CEO</span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1 b2"><span class="b0"></span></p>
                <p class="b1"><span class="b0">Youwei Zhen, CEO</span></p>
        </div>
    )
}
export default Bylaws