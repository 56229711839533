import './UnderConstruction.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Content1 from '../../components/content1/content1.js'


function UnderConstruction() {
  return (
    <div className="Event">
      <Menu />
      <Content1>
        <div className="linkStatus" style={{backgroundColor: "#36393E", borderRadius: "10px", width: "50%", margin: "auto", alignItems: "center", padding: "20px"}}>
          <h1>Coming soon...</h1>
          <h2>This page is currently under construction, please come back in the future for fun events!</h2>
        </div>
      </Content1>
      <Footer/>
    </div>
  );
}

export default UnderConstruction;
