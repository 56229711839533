import React from "react";
import "./Disclaimers.css"

const Disclaimers = (props)=> {
    return(
        <div class="dc7">
                <p class="dc4"><span class="dc5 dc1">Updated: 07/01/21</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc5 dc6">Fair Use Disclaimer:</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc1">The site may contain content not authorized for use by its owner. </span><span
                        class="dc1">Under the </span><span class="dc1"><a class="dc3"
                                href="https://www.google.com/url?q=https://www.copyright.gov/fair-use/more-info.html&amp;sa=D&amp;source=editors&amp;ust=1625187377818000&amp;usg=AOvVaw0c72dTKtnwMEBtC5LVDeMD">principle
                                of fair use</a></span><span class="dc1">, copyrighted works can be used in certain circumstances without
                        the permission of the copyright owner. Botsitivity uses certain works to enhance the experience of users for
                        purposes that include research and teaching. </span><span class="dc1">According to </span><span
                        class="dc1 c2"><a class="dc3"
                                href="https://www.google.com/url?q=https://www.copyright.gov/title17/92chap1.html%23107&amp;sa=D&amp;source=editors&amp;ust=1625187377818000&amp;usg=AOvVaw3nhAaGJRN5hLGdvY6ZDQ3N">Section
                                107 of the Copyright Act</a></span><span class="dc1">: &ldquo;</span><span class="dc5 c1">the fair use of
                        a copyrighted work [&hellip;] for purposes such as criticism, comment, news reporting, teaching (including
                        multiple copies for classroom use), scholarship, or research, is not an infringement of
                        copyright.&rdquo;</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc5 dc6">Warranty Disclaimer:</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc5 dc1">The information provided by Botsitivity on botsitivity.org [and our mobile
                        application] is for general information purposes only. All information on the website is provided in good
                        faith. However, we make no representation, warranty, or guarantee of any kind, express, or implied,
                        regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on
                        our website. Botsitivity does not warrant that the services will a) be timely, uninterrupted or error-free
                        or operate in combination with any hardware, software, system, or data b) meet your requirements or
                        expectations c) be free from error has ordered that defects will be corrected d) be free of viruses or other
                        harmful components; or e) be entirely secured that the information you share with us will be secure. No
                        advice or information, whether oral or written, obtained from budget tivity or through the services, will
                        create any warranty not expressly made herein.</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc5 dc6">No Responsibility Disclaimer:</span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc1">To the maximum extent permitted by applicable law, the </span><span
                        class="dc1">Botsitivity</span><span class="dc5 dc1">&nbsp;entities shall not be liable for any indirect,
                        incidental, special, consequential or punitive damages, or any loss of profit or very bad news, weather in
                        cursor directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting
                        from a) through access to or use of or inability to access or use the services b) any conduct or content of
                        any third party on the services, including without limitation, and defamatory, offensive, or illegal conduct
                        of other users or third parties c) any contents obtained from the services; or d) unauthorized access, use
                        or alteration of your transmissions or content. he limitations of this subsection show apply to any theory
                        of liability, whether based on warranty, countract, statute, tort or otherwise, and whether or not the
                        Botsitvity entities have been informed of the possibility of any such damage, and even if a remedy forth
                        herein is found to have failed a of its essential purpose. </span></p>
                <p class="dc0"><span class="dc5 dc1"></span></p>
                <p class="dc4"><span class="dc5 dc6">No Guarantee Disclaimer:</span></p>
                <p class="dc0"><span class="dc1 dc5"></span></p>
                <p class="dc4"><span class="dc1">Botsitivity maintains this website to enhance public access to the Services. These
                        services are still in development and while it is constantly updated, there are no guarantees that it will
                        give expected results and accurate information. If you need specific advice or professional help, please
                        seek a professional who is licensed or knowledgable in that area.</span></p>
        </div>
    )
}
export default Disclaimers