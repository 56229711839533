import "./InputBox.css";
import React, { Component } from "react";

class InputBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validate: "",
      color: "",
    };
  }

  checkValidEmail = (event) => {
    if (event.target.value.length > 8) {
      if (
        event.target.value.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        this.setState({
          validate: "Valid Email",
          color: "#1a8227",
        });
      } else {
        this.setState({
          validate: "Invalid Email",
          color: "#d16527",
        });
      }
    }
  };

  checkValidPassword = (event) => {
    if (event.target.value.length > 8) {
      var contains = false;
      for (var x of [
        "!",
        "@",
        "#",
        "$",
        "%",
        "^",
        "&",
        "*",
        "(",
        ")",
        "-",
        "=",
        "'",
        '"',
        ";",
        ":",
        "/",
        "?",
        ",",
        ".",
        "<",
        ">",
      ]) {
        if (event.target.value.indexOf(x) != -1) {
          contains = true;
        }
      }

      if (contains) {
        this.setState({
          validate: "Ultimate Password",
          color: "#1a8227",
        });
      } else {
        this.setState({
          validate: "Strong Password",
          color: "#5da666",
        });
      }
    } else if (event.target.value.length > 5) {
      this.setState({
        validate: "Okay Password",
        color: "#f5b642",
      });
    } else {
      this.setState({
        validate: "Weak Password",
        color: "#d16527",
      });
    }
  };

  checkValid = (event) => {
    if (this.props.checkChangeSign) {
      if (this.props.type === "email") {
        this.checkValidEmail(event);
      } else if (this.props.type == "password") {
        this.checkValidPassword(event);
      }
    }
  };

  render() {
    let width = "100%";
    if (this.props.width) {
      width = this.props.width;
    }

    return (
      <div className="InputBox" style={{ marginLeft: this.props.marginL }}>
        <h1 className="LabelText">{this.props.label}</h1>
        <input
          type={this.props.type}
          className="InputLine"
          id={this.props.id}
          autoComplete="off"
          onChange={(event) => {
            this.checkValid(event);
          }}
          style={{ width: width }}
        ></input>
        <div className="centerCheck">
          <h3 className="invalid" style={{ color: this.state.color }}>
            {this.state.validate}
          </h3>
        </div>
      </div>
    );
  }
}

export default InputBox;
