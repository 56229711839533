import './Statements.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Disclaimers from "../../components/Disclaimers/Disclaimers.js"
import PrivacyPolicy from "../../components/PrivacyPolicy/PrivacyPolicy.js"
import CookieStatement from "../../components/CookieStatement/CookieStatement.js"
import React, { useEffect } from 'react';

function Statements() {

  useEffect(async()=>{  
    let parsed = window.location.href.split("#")
    
    if (parsed.length==2){
      if (parsed[1]=="privacypolicy"){
        document.getElementById("privacypolicy").scrollIntoView();
      }else if (parsed[1]=="disclaimers"){
        document.getElementById("disclaimers").scrollIntoView();
      }else if (parsed[1]=="cookiestatement"){
        document.getElementById("cookiestatement").scrollIntoView();

      }
    }

  })

  return (
    <div>
      <Menu />
      <br></br>
      <div className="innerStatementContent">
        <div className="titleBoxStatement" id="privacypolicy">
          <h1 className="titleStatementText">Privacy Policy</h1>
        </div>
        <div>
          <PrivacyPolicy/>
        </div>
        <div className="titleBoxStatement" id="disclaimers">
          <h1 className="titleStatementText">Disclaimers</h1>
        </div>
        <div>
          <Disclaimers/>
        </div>
        <div className="titleBoxStatement" id="cookiestatement">
          <h1 className="titleStatementText">Cookie Statement</h1>
        </div>
        <div>
          <CookieStatement/>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Statements;
