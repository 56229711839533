import React from "react";
import "./PrivacyPolicy.css"

const PrivacyPolicy = (props)=> {
    return(
        <div class="pc5">
                <p class="pc1"><span class="pc0">Last Updated: June 25th, 2021</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc0">This Privacy Policy explains how information about you is collected, used and
                        disclosed by Botsitivity Inc. and its affiliates. This Privacy Policy applies to information we collect when
                        you use our website, and other products and services or when you interact with us.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc0">If we make changes to the Privacy Policy, we will notify you by revising the date at
                        the top of the policy and, in some cases, we may provide you with additional notice. We encourage you to
                        review the Privacy Policy whenever you access the Services to stay informed about our information practices
                        and the choices available to you.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Collection of Information:</span></p>
                <p class="pc1 pc4"><span class="pc7"></span></p>
                <p class="pc1"><span class="pc0">We collect information you provide directly to us. For example, we collect
                        information when you create an account. The types of information we may collect include basic user
                        information (such as your name, email address and photograph), and any other information you choose to
                        provide.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc0">We log information about your use of the Services, including the type of browser you
                        use, access times, chat history, your IP address and the page you visited before navigating to our
                        Services.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc0">We use various technologies to collect information, and this may include sending
                        cookies to your computer or mobile device. Cookies are small data files stored on your hard drive or in
                        device memory that help us to improve our Services and your experience, see which areas and features of our
                        Services are popular and count visits.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Use of Information:</span></p>
                <p class="pc1 pc4"><span class="pc7"></span></p>
                <p class="pc1"><span class="pc0">We use information about you to provide, maintain, and improve our Services, such as
                        to train our bot and send you related information, including emails. We may also use the information we
                        collect to do the following:</span></p>
                <ul class="pc3 lst-kix_tyklhlhu1hjj-0 start">
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Send you technical notices, updates, security alerts and support
                                and administrative messages</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Monitor and analyze trends, usage and activities in connection
                                with our Services</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Personalize and improve the Services and provide content or
                                features that match user profiles or interests</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Connect you to professional help if danger is detected</span>
                        </li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Process and deliver events entries and rewards</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">Carry out any other purpose described to you at the time the
                                information was collected</span></li>
                </ul>
                <p class="pc1 pc4 pc6"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Sharing of Information:</span></p>
                <p class="pc1"><span class="pc0">We may share information about you as follows or as otherwise described in this
                        Privacy Policy:</span></p>
                <ul class="pc3 lst-kix_tyklhlhu1hjj-0">
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">With vendors, consultants and other service providers who need
                                access to such information to carry out work on our behalf</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">In response to a request for information if we believe disclosure
                                is in accordance with any applicable law, regulation or legal process, or as otherwise required by any
                                applicable law, rule or regulation</span></li>
                        <li class="pc1 c2 li-bullet-0"><span>If we believe your actions are inconsistent with the spirit or language of
                                our user agreements or policies, or to protect the rights, property and safety of
                        </span><span>Botsitivity</span><span class="pc0">&nbsp;or others</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">In connection with, or during negotiations of, any merger, sale
                                of company assets, financing or acquisition of all or a portion of our business to another
                                company</span></li>
                        <li class="pc1 c2 li-bullet-0"><span class="pc0">With your consent or at your direction</span></li>
                </ul>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc0">We may also share aggregated or de-identified information, which cannot reasonably be
                        used to identify you.</span></p>
                <p class="pc1 c4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Security</span></p>
                <p class="pc1"><span>Botsitivity </span><span class="pc0">takes reasonable measures to help protect information about
                        you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Data Retention</span></p>
                <p class="pc1"><span class="pc0">We store the information we collect for as long as is necessary for the purpose(s)
                        for which we originally collected it. We may retain certain information for legitimate business purposes or
                        as required by law.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">International Data Transfers</span></p>
                <p class="pc1"><span>Botaitivity</span><span class="pc0">&nbsp;is based in the United States and the information we
                        collect is governed by U.S. law. By accessing or using the Services or otherwise providing information to
                        us, you consent to the processing, storage and transfer of information in and to the United States and other
                        countries where you may not have the same rights and protections as you do under local law.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc7">Account Information</span></p>
                <p class="pc1"><span class="pc0">You may update, correct or delete information about you at any time by logging into
                        your account and editing your user profile. If you wish to delete your account, please cancel your account
                        in the Settings section, but note that we may retain certain information as required by law or for
                        legitimate business purposes. We may also retain cached or archived copies of information about you for a
                        certain period of time.</span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1"><span class="pc9 c10">Contact </span><span class="pc9">U</span><span class="pc7">s</span></p>
                <p class="pc1"><span>If you have any questions about this Privacy Policy, please contact us at </span><span
                        class="pc8"><a class="pc11" href="mailto:general@civiso.org">general@botsitivity.org</a></span><span
                        class="pc0">. </span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
                <p class="pc1 pc4"><span class="pc0"></span></p>
        </div>
    )
}
export default PrivacyPolicy