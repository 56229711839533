import './Status.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Volunteer from "../../images/volunteer1white.png"
import Data from "../../images/datawhite.png"
import Production from "../../images/productionwhite.png"


function Status() {
  return (
    <div className="statusBackground">
      <Menu />
      <div className="statusContent">
        <ul>
          <li>
            <div className="stepImageDiv">
              <img className="imageCompletedStatus" src={Volunteer}/>
            </div>
            <p className="stepFonts statusGreenText">Recruiting Volunteers</p>
          </li>
          <li>
            <div className="stepImageDiv">
              <img className="imageUncompletedStatus" src={Data}/>
            </div>
            <p className="stepFonts">Collecting Data</p>
          </li>
          <li>
            <div className="stepImageDiv">
              <img className="imageUncompletedStatus" src={Production}/>
            </div>
            <p className="stepFonts">Final Production</p>
          </li>
        </ul>
      </div>
      <Footer/>
    </div>
  );
}

export default Status;
