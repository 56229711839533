import "./MassEmail.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import React, { useEffect, useState, Component, PropTypes } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Button from "../../components/ButtonWhite/buttonWhite";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function MassEmail() {
  const baseCompose = (
    <div className="volunteerEmptyFont">Select a recipient to start</div>
  );
  const [allVolunteers, setVolunteers] = useState([]);
  const [composeMessage, setComposeMessage] = useState(baseCompose);

  const cookies = new Cookies();
  const session = cookies.get("session");

  const buttonHeight = "50px";
  const buttonWidth = "99%";

  let users = [];

  //ckeditor stuff >>
  const toolbar = [
    "bold",
    "italic",
    "link",
    "undo",
    "redo",
    "numberedList",
    "bulletedList",
  ];
  const [value, setValue] = useState("");
  const handleMessageChange = (e, editor) => {
    const data = editor.getData().toString();
    console.log(data);
    setValue(data);
  };
  //ckeditor stuff <<

  function selectAll() {
    if (document.querySelector("body").classList.contains("all-selected")) {
      document.querySelector("body").classList.remove("all-selected");
      document.querySelectorAll(".volunteers").forEach((obj, index) => {
        obj.classList.add("selectedVolunteers");
        selectedVolunteer(index);
      });
    } else {
      document.querySelector("body").classList.add("all-selected");
      document.querySelectorAll(".volunteers").forEach((obj, index) => {
        obj.classList.remove("selectedVolunteers");
        selectedVolunteer(index);
      });
    }
  }

  async function sendEmail() {
    const subject = document.getElementById("subject").value;
    const message = document.querySelector("#preview").innerText;

    setComposeMessage(<div className="volunteerEmptyFont">Message sent!</div>);
    setValue("");
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/massemail`;
    if (window.location.hostname == "localhost")
      postURL = `${window.location.protocol}//${window.location.hostname}:2000/massemail`;
    await axios.post(postURL, {
      session: session,
      users: users,
      subject: subject,
      message: message,
    });
  }

  const selectedVolunteer = (id) => {
    let newArr = [];

    document.getElementById(`vol${id}`).classList.toggle("selectedVolunteers");

    let selected = document.querySelectorAll(".selectedVolunteers");

    selected.forEach((obj) => {
      let newId = obj.id.replace("vol", "");
      newArr.push(allVolunteers[newId]);
    });

    users = newArr;
    showComposing();
  };

  const renderVolunteers = () => {
    let vol = [
      <Button
        content="Select All"
        id="selectAll"
        Height={buttonHeight}
        Width={buttonWidth}
        func={selectAll}
      ></Button>,
    ];
    let index = 0;
    allVolunteers.sort(function (a, b) {
      var nameA = a.fname.toUpperCase(); // ignore upper and lowercase
      var nameB = b.fname.toUpperCase(); // ignore upper and lowercase
      var nameAA = a.lname.toUpperCase(); // ignore upper and lowercase
      var nameBB = b.lname.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (nameA === nameB) {
        if (nameAA < nameBB) {
          return -1;
        }
        if (nameAA > nameBB) {
          return 1;
        }
      }
      // names must be equal
      return 0;
    });

    allVolunteers.forEach((volunteer) => {
      let copied = index;
      vol.push(
        <div
          className="volunteers"
          onClick={() => {
            selectedVolunteer(copied);
          }}
          id={`vol${index}`}
        >
          <div>
            {volunteer.fname} {volunteer.lname}
          </div>
          <div className="subemail">{volunteer.email}</div>
        </div>
      );
      index++;
    });
    return vol;
  };

  const showComposing = () => {
    if (users.length === 0) {
      setValue("");
      setComposeMessage(baseCompose);
    } else {
      setValue("Preview in HTML");
      setComposeMessage(
        <form
          onSubmit={function (e) {
            e.preventDefault();
            sendEmail();
          }}
        >
          <div>
            <label>Subject: </label>
            <input type="text" id="subject" name="subject" required />
          </div>
          <div>
            <label>Message: </label>
            <CKEditor
              editor={ClassicEditor}
              onChange={handleMessageChange}
              config={{
                toolbar: toolbar,
              }}
            />
          </div>

          <div>
            <input type="submit" value="Send" id="submit" />
          </div>

          <div></div>
        </form>
      );
    }
  };

  useEffect(async () => {
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/getvolunteers`;
    if (window.location.hostname == "localhost")
      postURL = `${window.location.protocol}//${window.location.hostname}:2000/getvolunteers`;
    let fetchVolunteers = await axios.post(postURL, { session: session });
    setVolunteers(fetchVolunteers.data.payload);
  }, []);

  return (
    <div>
      <Menu />
      <div className="volunteerHome">
        <div className="displayVolunteer" id="volunteers">
          {renderVolunteers()}
        </div>
        <div className="currentVolunteer messagecompose">
          {composeMessage} <div id="preview">{value}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MassEmail;
