import "./AccountCreation.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import checkmark from "../../images/check-mark.png";

function PageNotFound() {
  return (
    <div>
      <Menu />
      <div className="AccountcreatedBackground">
        <div className="accountCreatedBox">
          <img className="image" src={checkmark} alt=""></img>
          <div className="AccountnotificationAccountDiv">
            <p>
              Your account has been created, please verify your email!<br></br>
              Make sure to check your spam folder too.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
