import "./Sign-up.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import axios from "axios";
import InputBox from "../../components/InputBox/InputBox.js";
import React, { useEffect, useState } from "react";
import YellowButton from "../../components/ButtonYellow/buttonYellow.js";
import TermsOfService from "../../components/TermsOfService/TermsOfService.js";
import CheckBox from "../../components/Checkbox/Checkbox.js";

function SignUp() {
  const [marginLeft, setMarginLeft] = useState("0%");
  const [buttonWidth, setButtonWidth] = useState("250px");
  const [buttonHeight, setButtonHeight] = useState("100px");
  const [mobileClass, setMobileClass] = useState("bottomForm");
  const [widthFname, setWidthFname] = useState("90%");

  useEffect(async () => {
    if (window.innerWidth <= 1000) {
      setMarginLeft("10%");
      setWidthFname("100%");
      setMobileClass("topForm");
    }
    if (window.innerWidth < 600) {
      setButtonWidth("150px");
      setButtonHeight("75px");
    }
  });

  const closeTos = () => {
    document.getElementById("outerwrapper").style.height = "100%";
    document.getElementById("tos").style.display = "none";
  };

  const checkInformation = async () => {
    let fname = document.getElementById("fname").value;
    let lname = document.getElementById("lname").value;
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;
    let warning = document.getElementById("warningMessage");

    if (!fname) warning.innerHTML = "Please fill out your first name!";
    else if (!lname) warning.innerHTML = "Please fill out your last name!";
    else if (!email) warning.innerHTML = "Please fill out your email!";
    else if (!password) warning.innerHTML = "Please fill out your password!";
    else {
      document.getElementById("tos").style.display = "flex";
      document.getElementById("outerwrapper").style.height = "0vh";
    }
  };

  const submit = async()=>{
    
    let check1 = document.getElementById("check1").checked
    let check2 = document.getElementById("check2").checked
    
    let fname = document.getElementById("fname").value
    let lname = document.getElementById("lname").value
    let email = document.getElementById("email").value
    let password = document.getElementById("password").value
    
    if (check1){
      closeTos()
      let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/signup`
      if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/signup`
      document.getElementById("warningMessage").innerHTML = "Signing up..."
      let signup = await axios.post(postURL,{fname: fname, lname: lname, email: email, password: password}).catch(()=>{})
      if (signup.data.code==200){
        window.location.replace("/accountmade")
      }else{
        document.getElementById("warningMessage").innerHTML = signup.data.message
      }
    }
  };

  return (
    <div className="SignUpOuter">
      <Menu />
      <div className="OuterWrapper" id="outerwrapper">
        <div className="centerWrap">
          <h1 className="signLogTitleText">Sign Up</h1>
          <div className="topForm">
            <InputBox
              label="First Name:"
              marginL={marginLeft}
              type="fname"
              id="fname"
              checkChangeSign={false}
              width={widthFname}
            ></InputBox>
            <InputBox
              label="Last Name:"
              marginL={marginLeft}
              type="lname"
              id="lname"
              checkChangeSign={false}
              width="100%"
            ></InputBox>
          </div>
          <div className={mobileClass}>
            <InputBox
              label="Email:"
              marginL={marginLeft}
              type="email"
              id="email"
              checkChangeSign={true}
            ></InputBox>
          </div>
          <div className={mobileClass}>
            <InputBox
              label="Password:"
              marginL={marginLeft}
              type="password"
              id="password"
              checkChangeSign={true}
            ></InputBox>
          </div>
          <div id="warningMessage"></div>
          <div className="proceedButtonContainer">
            <YellowButton
              content="Proceed"
              id="proceed"
              Height={buttonHeight}
              Width={buttonWidth}
              func={checkInformation}
            ></YellowButton>
          </div>
        </div>
      </div>
      <div className="TOS" id="tos">
        <div
          className="closeTOS"
          onClick={() => {
            closeTos();
          }}
        >
          X
        </div>
        <div className="titleTOS">
          <h3>TERMS OF SERVICES</h3>
        </div>
        <div className="TOSContent">
          <TermsOfService />
        </div>
        <div className="checkBoxForm">
          <div>
            <CheckBox
              id="check1"
              content="I have read and accept the Terms Of Service"
            />
            <CheckBox
              id="check2"
              content="I'd like to receive updates and reminders"
            />
          </div>
        </div>
        <div id="warningMessage2"></div>
        <div className="submitButtonContainer">
          <YellowButton
            content="Submit"
            Height={buttonHeight}
            Width={buttonWidth}
            func={submit}
          ></YellowButton>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
