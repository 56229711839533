import React from "react";
import "./CookieStatement.css"

const CookieStatement = (props)=> {
    return(
        <div class="cookieStatementText">
                <h1>Disclaimers: Our website utilizes cookies to enhance user experience, and to perform certain functions.</h1>
        </div>
    )
}
export default CookieStatement