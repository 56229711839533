import './ResetPassword.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import InputLine from "../../components/InputBox/InputBox.js"
import SubmitButton from "../../components/ButtonYellow/buttonYellow.js"
import axios from 'axios';
import React, { useState, useEffect } from 'react';

function ResetPassoword() {

  const rToken = document.URL.split("reset/")[1]

  const submit = async()=>{
    document.getElementById("submitBtn").innerHTML = "Resetting..."
    let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/newpassword`
    if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/newpassword`
    let data = await axios.post(postURL,{ resetToken: rToken, newPassword: document.getElementById("password").value })
    console.log(data)
    if (data.data.code==200){
      document.getElementById("submitBtn").innerHTML = "Successfully changed your password!"
    }else{
      document.getElementById("submitBtn").innerHTML = "Error: " + data.data.message
    }
  }

  const [buttonWidth, setButtonWidth]  = useState("250px")

  useEffect(async()=>{
    if (window.innerWidth<=900){
      setButtonWidth("150px")
    }
    let data = await axios.post(`http://${process.env.REACT_APP_API_URL}/validateRToken`, { resetToken: rToken})
    if (data.data.code != 200){
      window.location.replace("/404")
    }
  },[])

  return (
    <div>
      <Menu />
      <div className="ResetcreatedBackground">
        <div className="ResetForgotPassowrdDiv">
          <InputLine label="New Password:" marginL="0%" id="password"></InputLine>
          <div id="submitBtn">
            <SubmitButton content="Submit" Height="50px" Width={buttonWidth} func={submit}></SubmitButton>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassoword;
