import "./teampage.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import NoProfile from "../../images/noprofile.png"

function OurTeam() {
  return (
    <div>
      <Menu />
      <div className="ourteam">

        <div className="teamMember">
          <div>
            <img className="teamMemberPic" src={NoProfile} alt="Some member"></img>
            <h2>Name</h2>
            <p>
              Description of the team member here.
              Coming soon.
            </p>
          </div>
        </div>

        <div className="teamMember">
          <div>
            <img className="teamMemberPic" src={NoProfile} alt="Some member"></img>
            <h2>Name</h2>
            <p>
              Description of the team member here.
              Coming soon.
            </p>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default OurTeam;
