import React from "react";
import "./TermsOfService.css"

const TermsOfService = (props)=> {
    return(
        <div class="c19">
            <p class="c5"><span class="c0">Last Updated: June 25th, 2021</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c6">INTRODUCTION</span></p>
            <p class="c5 c9"><span class="c6"></span></p>
            <p class="c5"><span class="c4">Thank you for choosing </span><span class="c4">Botsitivity</span><span
                    class="c4">.
                    Botsitivity provides a personalized, secured, and safe chatting platform. By signing up with
                </span><span class="c4">Botsitivity</span><span class="c4">&nbsp;services, </span><span class="c0">including
                    all
                    associated features and functionalities, websites and user interfaces, as well as all content and
                    software
                    applications associated with our services, or accessing any other content or material that is made
                    available
                    through the service, you are entering a binding contract with Botsitivity Inc. </span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c4">Your agreement with us includes these Terms and any additional terms that you
                    agree
                    to, as discussed in the </span><span class="c4">Entire Agreement</span><span class="c0">&nbsp;section
                    below,
                    other than terms with any third parties. You acknowledge that you have read and understood the
                    Agreements,
                    accept these Agreements, and agree to be bound by them. If you don&#39;t agree with (or cannot comply
                    with)
                    the Agreements, then you may not use the Botsitivity Service or access any Content.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c0">In order to use the Botsitivity Service and access any content, you need to (1)
                    be 10
                    years or older, or be 8 years or older and have your parent or guardian&#39;s consent to the Agreements
                    and
                    (2) have the power to enter a binding contract with us and not be barred from doing so under any
                    applicable
                    laws. You also promise that any registration information that you submit to Botsitivity is true,
                    accurate,
                    and complete, and you agree to keep it that way at all times.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c0">If you are entering into this Agreement on behalf of a company (or other entity),
                    you
                    represent that you are an employee or agent of such company (or other entity) and you have the authority
                    to
                    enter into this Agreement on behalf of such company (or other entity).</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c0">For the purpose of this Agreement, you and, if applicable, such company (or other
                    entity) constitute &ldquo;Customer&rdquo; or &ldquo;you&rdquo;.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c0">The &ldquo;Effective Date&rdquo; of this Agreement is the date you register for
                    an
                    Account and agree to the terms and conditions of this Agreement.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0 start" start="1">
                <li class="c2 li-bullet-0"><span class="c6">LIMITED LICENSE TO USE THE SERVICES</span></li>
            </ol>
            <p class="c5 c9 c12"><span class="c6"></span></p>
            <p class="c5"><span class="c4">Subject to your compliance with these Terms and all other applicable Rules
                    including
                    but not limited to the Community Guidelines, you are granted a limited, non-exclusive,
                    non-sublicensable,
                    revocable, non-transferable license to access and use the Services solely for your personal and
                    non-commercial use. No licenses or rights are granted to you by implication or otherwise under any
                    intellectual property rights owned or controlled by </span><span class="c4">Botsitivity</span><span
                    class="c0">&nbsp;or its licensors, except for licenses and rights expressly granted in these Terms.
                    Botsitivity can terminate this license as provided below.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c0">You are solely responsible for compliance with any and all laws, rules, and
                    regulations that may apply to your use of the Services. You agree that you will comply with these Terms
                    and
                    the Community Guidelines and will not, and will not assist or enable others to:</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <ul class="c15 lst-kix_wyr8muo8tj56-0 start">
                <li class="c2 li-bullet-0"><span class="c0">breach or circumvent any applicable laws or regulations,
                        agreements
                        with third parties, third-party rights, or our Terms or Rules</span></li>
                <li class="c2 li-bullet-0"><span class="c4">use the Services for any commercial or other purposes that are
                        not
                        expressly permitted by these Terms or in a manner that falsely implies Botsitivity&rsquo;s
                        endorsement,
                        partnership or otherwise misleads others as to your affiliation with </span><span
                        class="c4">Botsitivity</span></li>
                <li class="c2 li-bullet-0"><span class="c0">license, sell, transfer, assign, distribute, host, or otherwise
                        commercially exploit the Services</span></li>
                <li class="c2 li-bullet-0"><span class="c0">except as explicitly stated herein, copy, reproduce, distribute,
                        republish, download, display, post or transmit the Services, in whole or in part, in any form or by
                        any
                        means</span></li>
                <li class="c2 li-bullet-0"><span class="c0">use, display, mirror or frame the Services or any individual
                        element
                        within the Services, the Botsitivity name, any Botsitivity trademark, logo or other proprietary
                        information, or the layout and design of any page or form contained on a page in the Services,
                        without
                        Botsitivity&rsquo;s express written consent</span></li>
                <li class="c2 li-bullet-0"><span class="c0">use any robots, spider, crawler, scraper or other automated
                        means or
                        processes to access, collect data or other content from or otherwise interact with the Services for
                        any
                        purpose</span></li>
                <li class="c2 li-bullet-0"><span class="c0">avoid, bypass, remove, deactivate, impair, descramble, or
                        otherwise
                        attempt to circumvent any technological measure implemented by Botsitivity or any of
                        Botsitivity&rsquo;s
                        providers to protect the Services</span></li>
                <li class="c2 li-bullet-0"><span class="c0">modify, make derivative works of, attempt to decipher,
                        decompile,
                        disassemble or reverse engineer any of the software used to provide the Services</span></li>
                <li class="c2 li-bullet-0"><span class="c0">take any action that damages or adversely affects, or could
                        damage
                        or adversely affect the performance or proper functioning of the Services</span></li>
                <li class="c2 li-bullet-0"><span class="c0">violate or infringe anyone else&rsquo;s rights or otherwise
                        cause or
                        threaten harm to anyone</span></li>
            </ul>
            <p class="c5 c9"><span class="c0"></span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="2">
                <li class="c2 li-bullet-0"><span class="c6">USER CONTENT, DISPUTES, AND CONDUCT</span></li>
            </ol>
            <p class="c5 c12 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c4">You acknowledge and agree that any user content, including without limitation
                    text
                    chats and video chats, is not created, endorsed or controlled by Botsitivity. Botsitivity will not under
                    any
                    circumstances be liable for any user content or activity within the Services. </span><span
                    class="c4">Botsitivity</span><span class="c4">&nbsp;is not responsible for information or content that
                    you
                    choose to share within or through the Services nor is Botsitivity responsible for the content or actions
                    of
                    other users of the Services. </span><span class="c4">Botsitivity</span><span class="c0">&nbsp;is not
                    responsible for maintaining copies of any information or communications you choose to submit to or
                    through
                    the Services.</span></p>
            <p class="c5 c9"><span class="c0"></span></p>
            <p class="c5"><span class="c4">You are solely responsible for your interaction with other users of the Services
                    and
                    other parties that you come in contact with through the Services. To the fullest extent permitted by
                    applicable law, </span><span class="c4">Botsitivity</span><span class="c0">&nbsp;hereby disclaims any
                    and
                    all liability to you or any third party relating to your use of the Services. You acknowledge and agree
                    that
                    Botsitivity does not have any special relationship with you as an end user, and as such, Botsitivity
                    does
                    not owe you any duty to protect you from the acts of the bot or other users.</span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="3">
                <li class="c7 li-bullet-0">
                    <h2 id="h.436293y73ba" style={{display: "inline"}}><span class="c6">INTELLECTUAL PROPERTY RIGHTS</span></h2>
                </li>
            </ol>
            <p class="c5"><span class="c4">The Services may, in their entirety or in part, be protected by copyright,
                    trademark
                    and/or other laws of the United States and other countries. You acknowledge and agree that the Services,
                    including all associated intellectual property rights, are the exclusive property of Botsitivity and/or
                    its
                    licensors or authorizing third parties. You will not remove, alter or obscure any copyright, trademark,
                    service mark or other proprietary rights notices incorporated in or accompanying the Services. All
                    trademarks, service marks, logos, trade names, trade dress and any other source identifiers of
                    Botsitivity
                    used on or in connection with the Services are trademarks or registered trademarks of </span><span
                    class="c4">Botsitivity</span><span class="c0">&nbsp;in the United States and abroad. Trademarks, service
                    marks, logos, trade names and any other proprietary designations of third parties used on or in
                    connection
                    with the Services are used for identification purposes only and may be the property of their respective
                    owners. Use of any third-party trademark is intended only to identify the trademark owner and its goods
                    and
                    services, and is not intended to imply any association between the trademark owner and
                    Botsitivity.</span>
            </p>
            <p class="c5 c9"><span class="c0"></span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="4">
                <li class="c7 li-bullet-0">
                    <h2 id="h.jc50zanjgys4" style={{display: "inline"}}><span class="c6">ASSUMPTION OF RISK AND DISCLAIMER OF
                            WARRANTIES</span></h2>
                </li>
            </ol>
            <p class="c1"><span class="c0">Assumption of risk: you acknowledge and agree that use of the services, including
                    your interactions with other users, may carry inherent risk and by accessing and using the Services, you
                    choose to assume those risks voluntarily. To the fullest extent permitted by applicable law, you assume
                    full
                    responsibility for your use of the services, including your interactions with other users.</span></p>
            <p class="c1"><span class="c4">To the fullest extent permitted under applicable law, you knowingly, voluntarily
                    and
                    freely assume all risks, both known and unknown, of accessing or using the services, even if those risks
                    arise from the negligence or carelessness of </span><span class="c4">Botsitivity</span><span
                    class="c0">,
                    third-parties including other users of the services, or defects in the services.</span></p>
            <p class="c1"><span class="c0">No warranties: to the fullest extent permitted under applicable law, Botsitivity
                    provides the services on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; and &ldquo;with all
                    faults&rdquo; basis, without warranty of any kind. Botsitivity and its affiliates and licensors disclaim
                    all
                    warranties and conditions of any kind, express or implied, including but not limited to, warranties of
                    title, implied warranties of non-infringement, merchantability, fitness for a particular purpose, and
                    implied warranties arising from a particular course of dealing or usage of trade. Without limiting the
                    foregoing, neither Botsitivity nor any of its affiliates or licensors, nor any of its or their officers,
                    directors, licensors, employees or representatives represent or warrant (i) that the services will meet
                    your
                    requirements or be accurate, truthful, complete, reliable, or error free, (ii) that the services will
                    always
                    be available or will be uninterrupted, accessible, timely, responsive, or secure, (iii) that any errors
                    or
                    defects will be corrected, or that the services will be free from viruses, worms, trojan horses or other
                    harmful properties, (iv) the accuracy, reliability, timeliness or completeness of any content available
                    on
                    or through the services, (v) any implied warranty arising from course of dealing or usage of trade, or
                    (vi)
                    that any content provided via the services is non-infringing. No information or advice provided through
                    the
                    services by Botsitivity or by Botsitivity&rsquo;s employees or agents shall create any warranty. some
                    jurisdictions do not allow the exclusion of certain warranties, so some of the above limitations and
                    exclusions may not apply to you.</span></p>
            <p class="c1"><span class="c0">Other users of the services. Botsitivity has no control over and does not make,
                    and
                    hereby expressly disclaims, any representations, warranties or guarantees as to the conduct, acts or
                    omissions of other users of the services. You acknowledge and agree that you shall look solely to the
                    other
                    users, and not Botsitivity, with respect to any claims or causes of action arising from or relating to
                    the
                    actions or conduct of other users of the services. To the fullest extent permitted under applicable law,
                    under no circumstances shall Botsitivity be responsible for any loss, damage or injury resulting from
                    any
                    action, conduct or omission of any other user of the services.</span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="5">
                <li class="c1 c12 c13 li-bullet-0"><span class="c6">LIMITATION OF LIABILITY</span></li>
            </ol>
            <p class="c1"><span class="c0">Limitations on Botsitivity&rsquo;s Liability: you acknowledge and agree that, to
                    the
                    fullest extent permitted by law, the entire risk arising out of your access to and use of the services
                    remains with you. Neither botsitivity nor any other party involved in creating, producing, or delivering
                    the
                    services will be liable to you or any third party for any direct, indirect, incidental, special,
                    punitive,
                    exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service
                    interruption, computer damage or system failure or the cost of substitute products or services, or for
                    any
                    damages for personal or bodily injury or emotional distress arising out of or in connection with (i)
                    these
                    terms, (ii) the use of the services, including but not limited to any damage caused by any reliance on,
                    or
                    any delays, inaccuracies, errors or omissions in, the services, whether provided by botsitivity or by
                    third
                    parties, (iii) the use of or inability to use the services for any reason, or (iv) your communications,
                    interactions or dealings with, or the conduct of, other users of the services, whether based on
                    warranty,
                    contract, tort (including negligence), product liability or any other legal theory, and whether or not
                    botsitivity has been informed of the possibility of such damage, even if a limited remedy set forth
                    herein
                    is found to have failed of its essential purpose.</span></p>
            <p class="c1"><span class="c0">The limitations of damages set forth above are fundamental elements of the basis
                    of
                    the bargain between Botsitivity and you. Some jurisdictions do not allow the exclusion or limitation of
                    liability for consequential or incidental damages, so some of the above limitations and exclusions may
                    not
                    apply to you.</span></p>
            <p class="c1"><span class="c4">No Liability for Non-Botsitivity Actions</span><span class="c17">:</span><span
                    class="c0">&nbsp;to the fullest extent permitted by applicable law, in no event will botsitivity be
                    liable
                    for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential,
                    and/or
                    incidental, arising out of or relating to the conduct, acts or omissions of you or any other third
                    party,
                    including other users of the services, in connection with the use of the services, including without
                    limitation, bodily injury, emotional distress, and/or any other damages. Some jurisdictions do not allow
                    the
                    exclusion or limitation of liability for consequential or incidental damages, so some of the above
                    limitations and exclusions may not apply to you.</span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="6">
                <li class="c1 c12 c13 li-bullet-0"><span class="c6">INDEMNIFICATION</span></li>
            </ol>
            <p class="c1"><span class="c4">To the maximum extent permitted by applicable law, you agree to release, defend
                    (at
                    Botsitivity&rsquo;s option), indemnify, and hold Botsitivity and its affiliates and subsidiaries, and
                    their
                    officers, directors, employees and agents, harmless from and against any claims, liabilities, damages,
                    losses, and expenses, including without limitation, reasonable attorney and accounting fees, arising out
                    of
                    or in any way connected with (i) your breach or alleged breach of these Terms or any other applicable
                    policies of </span><span class="c4">Botsitivity</span><span class="c4">&nbsp;(including but not limited
                    to
                    the Guidelines or Rules), (ii) your use of the Services other than as authorized by these Terms, the
                    Guidelines or Rules, (iii) your interactions with other users of the Services, including without
                    limitation
                    any injuries, losses or damages (whether compensatory, direct, incidental, consequential or otherwise)
                    of
                    any kind arising in connection with or as a result of your interactions, (iv) any information or
                    materials
                    you submit through the Services, or (v) your violation, or alleged violation, of any laws, regulations
                    or
                    third-party rights. Botsitivity may assume exclusive control of any defense of any Claims (which shall
                    not
                    excuse your obligation to indemnify </span><span class="c4">Botsitivity</span><span class="c4">), and
                    you
                    agree to fully cooperate with </span><span class="c4">Botsitivity</span><span class="c0">&nbsp;in such
                    an
                    event. You shall not settle any claims without prior written consent from Botsitivity.</span></p>
            <ol class="c15 lst-kix_2gh2eqf8mlmd-0" start="7">
                <li class="c7 li-bullet-0">
                    <h2 id="h.37xg3yzgumts" style={{display: "inline"}}><span class="c6">DISPUTE RESOLUTION: AGREEMENT TO
                            ARBITRARY</span></h2>
                </li>
            </ol>
            <h3 class="c3" id="h.svrs1dv5u9dc"><span class="c0">7.1 Agreement to Arbitrate and Timing of Claims</span></h3>
            <p class="c1"><span class="c4">You and Botsitivity mutually agree that any dispute, claim or controversy arising
                    out
                    of or relating in any way to these terms or the applicability, breach, termination, validity,
                    enforcement or
                    interpretation thereof or to the access to and use of the services, whether based in contract, statute,
                    regulation, ordinance, tort (including without limitation, fraud, misrepresentation, fraudulent
                    inducement,
                    or negligence), or any other legal or equitable theory will be settled by binding individual
                    arbitration.
                    Arbitration means that the dispute will be resolved by a neutral arbitrator instead of in a court by a
                    judge
                    or jury. The arbitrator will decide all threshold questions, including but not limited to issues
                    relating to
                    the enforceability, revocability, or validity of this arbitration agreement and whether either party
                    lacks
                    standing to assert his/her/its claim(s).</span></p>
            <p class="c1"><span class="c0">you acknowledge and agree that, regardless of any statute or law to the contrary,
                    any
                    claim or cause of action arising out of or related to these terms or your use of the services must be
                    filed
                    within one (1) year after such claim or cause of action arose or be forever barred.</span></p>
            <h3 class="c3" id="h.9lbtocstb295"><span class="c0">7.2 Exceptions to the Arbitration Agreement</span></h3>
            <p class="c1"><span class="c4">Notwithstanding the Arbitration Agreement, you and Botsitivity each agree that
                    (i)
                    any dispute that may be brought in small claims court may be instituted in a small claims court of
                    competent
                    jurisdiction, (ii) either you or </span><span class="c4">Botsitivity</span><span class="c0">&nbsp;may
                    seek
                    injunctive relief in any court of competent jurisdiction to enjoin infringement or other misuse of
                    either
                    party&rsquo;s intellectual property rights (including without limitation, violation of any data use
                    restrictions contained in these Terms or other misuse of the Services) or based on other exigent
                    circumstances (e.g., imminent danger or commission of a crime, hacking, cyber-attack).</span></p>
            <h3 class="c3" id="h.wnno4ou4c7uc"><span class="c0">7.3 Pre-Arbitration Notification and Good Faith
                    Negotiation</span></h3>
            <p class="c1"><span class="c4">Prior to initiating an arbitration, you agree to provide Botsitivity with notice
                    of
                    the dispute, which notice shall include a brief, written description of the dispute, the relief
                    requested
                    and your contact information. You must send any such notice to Botsitivity by email at
                    general@botsitivity.org, with &ldquo;</span><span class="c4">Botsitivity</span><span
                    class="c0">&nbsp;Disputes&rdquo; in the subject line. The parties agree to use their best efforts to
                    resolve
                    any dispute that is subject to the notification required under this section through informal
                    negotiation,
                    and good faith negotiations shall be a condition to either party initiating a lawsuit or arbitration in
                    accordance with these Terms. If, after a good faith effort to negotiate, one of us feels the dispute has
                    not
                    and cannot be resolved informally, the party intending to pursue arbitration agrees to notify the other
                    party via email prior to initiating the arbitration.</span></p>
            <h3 class="c3" id="h.4hqaaj4gsdjp"><span class="c0">7.4 The Arbitration</span></h3>
            <p class="c1"><span class="c4">Except as provided herein, if we cannot resolve a Dispute by informal
                    negotiation,
                    any Dispute will be resolved only by binding arbitration to be conducted by JAMS under its then current
                    and
                    applicable rules and procedures (&ldquo;JAMS Rules&rdquo;), which are located at </span><span
                    class="c4 c18"><a class="c11"
                        href="https://www.google.com/url?q=https://www.jamsadr.com/&amp;sa=D&amp;source=editors&amp;ust=1624677312063000&amp;usg=AOvVaw38Xpoa-zIYpAKmO65N87Jc">www.jamsadr.com</a></span><span
                    class="c0">, and the rules set forth in these Terms. If there is a conflict between the JAMS Rules and
                    the
                    rules set forth in these Terms, the rules set forth in these Terms will govern.</span></p>
            <p class="c1"><span class="c0">The arbitration will be conducted in English by a single arbitrator selected in
                    accordance with JAMS Rules and those rules will govern the payment of all filing, administration, and
                    arbitrator fees unless this Arbitration Agreement expressly provides otherwise. For U.S. residents, the
                    arbitration shall be conducted in the U.S. state in which you reside (subject to the ability of either
                    party
                    to appear at any in-person hearing by telephone or other remote means, as provided below). If the value
                    of
                    the relief sought is U.S. $25,000 or less, the arbitration will be conducted based solely on written
                    submissions; provided, however, that either party may request to have the arbitration conducted by
                    telephone
                    or other remote means or in-person hearing, which request shall be subject to the arbitrator&rsquo;s
                    discretion. Attendance at any in-person hearing may be made by telephone or other remote means by you
                    and/or
                    us, unless the arbitrator requires otherwise after hearing from the parties on the issue. Keeping in
                    mind
                    that arbitration is intended to be a fast and economical process, either party may file a dispositive
                    motion
                    to narrow the issues or claims. Subject to the exclusions and waivers in these Terms, the arbitrator may
                    award any individual relief or individual remedies that are permitted by applicable law. The
                    arbitrator&rsquo;s award shall be made in writing but need not provide a statement of reasons unless
                    requested by a party or required under applicable JAMS Rules. The arbitrator&rsquo;s award shall be
                    final
                    and may be enforced in any court of competent jurisdiction. Each party shall pay its own
                    attorneys&rsquo;
                    fees and costs unless there is an applicable statutory provision requiring the prevailing party to be
                    paid
                    its attorneys&rsquo; fees and costs, in which case, a prevailing party attorneys&rsquo; fees award shall
                    be
                    determined by applicable law.</span></p>
            <h3 class="c3" id="h.jrcirrd14hi8"><span class="c0">7.5 No Class Actions or Representative Proceedings</span>
            </h3>
            <p class="c1"><span class="c4">You and </span><span class="c4">Botsitivity</span><span
                    class="c4">&nbsp;acknowledge
                    and agree that to the fullest extent permitted by law, we are each waiving the right to participate as a
                    plaintiff or class member in any purported class action lawsuit, class</span><span class="c0">-wide
                    arbitration, private attorney general action, or any other representative proceeding as to all disputes.
                    You
                    and botsitivity agree that there will be no class arbitration or arbitration in which an individual
                    attempts
                    to resolve a dispute as a representative of another individual or group of individuals. Further, you and
                    botsitivity agree that a dispute cannot be brought as a class or other type of representative action,
                    whether within or outside of arbitration, or on behalf of any other individual or group of
                    individuals.</span></p>
            <h3 class="c3" id="h.yh3ag26e8stb"><span class="c0">7.6 Jury Trial Waiver</span></h3>
            <p class="c1 c14"><span class="c4">You and </span><span class="c4">Botsitivity</span><span
                    class="c4">&nbsp;acknowledge and agree that we are each waiving the right to a trial by jury as to all
                    arbitrable disputes and as to any dispute that proceeds in court rather than arbitration as provided
                    herein.</span></p>
            <h3 class="c3" id="h.u9uttxmrz8jq"><span class="c0">7.7 Severability</span></h3>
            <p class="c1"><span class="c0">Except as provided in Section 9.5, in the event that any portion of this
                    Arbitration
                    Agreement is deemed illegal or unenforceable, such provision shall be severed and the remainder of the
                    Arbitration Agreement shall be given full force and effect. If the arbitrator determines this Section 9
                    is
                    unenforceable, invalid or has been revoked as to any claim(s), then the Dispute as to such claim(s) will
                    be
                    decided by the courts or the United States District Court, and the parties irrevocably submit to the
                    exclusive jurisdiction of such courts.</span></p>
            <h2 class="c16 c14" id="h.cuxinf29eh1b"><span class="c6">8. TERM, TERMINATION, AND SURVIVAL</span></h2>
            <p class="c1"><span class="c4">This Agreement will remain in full force and effect while you use the Services in
                    accordance with these Terms and any additional applicable Rules. </span><span
                    class="c4">Botsitivity</span><span class="c0">&nbsp;may terminate this Agreement at any time without
                    notice
                    if we believe that you have breached this Agreement or the Community Guidelines, including but not
                    limited
                    to, by using the Services for non-personal use, engaging in prohibited activities, and any breach of
                    your
                    representations and warranties. All provisions of this Agreement which by their nature should survive
                    termination shall survive termination, including without limitation, ownership provisions, warranty
                    disclaimers, assumption of risk agreement, release of claims, indemnity, limitations of liability, and
                    dispute resolution.</span></p>
            <h2 class="c14 c16" id="h.fkzp2txyldqk"><span class="c6">9. GENERAL</span></h2>
            <h3 class="c3" id="h.vqjk6hht2s3t"><span class="c0">9.1 Privacy Notice and Law Enforcement Inquiries</span></h3>
            <p class="c1"><span class="c4">Botsitivity</span><span class="c0">&nbsp;maintains a Privacy Policy describing
                    the
                    collection, retention, and use of information related to your use of the Services. </span></p>
            <p class="c1"><span class="c0">Botsitivity&rsquo;s obligations are subject to existing laws and legal processes.
                    Therefore, Botsitivity complies with valid legal process (e.g., court order, search warrant, subpoena or
                    similar legal process) issued in compliance with applicable law from law enforcement agencies.</span>
            </p>
            <p class="c1"><span class="c4">Law enforcement may also submit requests for information and legal process from
                    an
                    official government-issued email address (e.g., name@agency.gov) to Botsitivity at
                    general@botsitivity.org
                    with &ldquo;</span><span class="c4">Botsitivity</span><span class="c4">&rdquo; in the subject line.
                    Non-law
                    enforcement requests should not be submitted to this email address. Botsitivity will not respond to
                    correspondence sent by non-law enforcement officials to this email address. Please note that the email
                    address for law enforcement requests is provided for convenience only and does not waive any objections
                </span><span class="c4">Botsitivity</span><span class="c0">&nbsp;may have, including the lack of
                    jurisdiction or
                    proper service.</span></p>
            <h3 class="c8" id="h.9rfsmj77o1ii"><span class="c0">9.2 Feedback</span></h3>
            <p class="c1"><span class="c4">We welcome and encourage you to provide feedback, comments and suggestions for
                    improvements to the Services. You may submit Feedback by emailing us at general@botsitivity.org with
                    &ldquo;</span><span class="c4">Botsitivity-Feedback</span><span class="c0">&rdquo; in the subject line.
                    Any
                    Feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting
                    Feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
                    perpetual license to use and publish those ideas and materials for any purpose, without compensation to
                    you.</span></p>
            <h3 class="c3" id="h.1o8ve84ymcu5"><span class="c0">9.3 Third-Party Links and Services</span></h3>
            <p class="c1"><span class="c0">The Services may contain links to other websites, businesses, resources and
                    advertisers, and other sites may link to the Services. Clicking on a link will redirect you away from
                    the
                    Services to a third-party site or service. Botsitivity is not responsible for examining or evaluating,
                    and
                    does not warrant the goods, services or offerings of any third party or the content of their websites or
                    advertisements. Consequently, Botsitivity does not assume any liability or responsibility for the
                    accuracy,
                    actions, products, services, practices, availability or content of such third parties. You should direct
                    any
                    concerns regarding other sites and services to their operators.</span></p>
            <h3 class="c8" id="h.a4uay7mf9am9"><span class="c0">9.4 Assignment</span></h3>
            <p class="c1"><span class="c4">You may not assign, transfer or delegate this Agreement and your rights and
                    obligations hereunder without Botsitivity&rsquo;s prior written consent. </span><span
                    class="c4">Botsitivity</span><span class="c0">&nbsp;may, without restriction, assign, transfer or
                    delegate
                    this Agreement and any rights and obligations hereunder, at its sole discretion.</span></p>
            <h3 class="c8" id="h.ea8i4f79wnfa"><span class="c0">9.5 Changes to the Services or the Terms</span></h3>
            <p class="c1"><span class="c4">Botsitivity</span><span class="c0">&nbsp;reserves the right, at any time and in
                    our
                    sole discretion, to amend, modify, suspend, or terminate, temporarily or permanently, the Services, and
                    any
                    part thereof, without notice to you. Botsitivity shall have no liability to you or any other person or
                    entity for any modification, suspension, or termination of the Services or any part thereof.</span></p>
            <p class="c1"><span class="c0">Botsitivity reserves the right to modify these Terms (effective on a prospective
                    basis) at any time in accordance with this provision. Therefore, you should review these Terms
                    regularly. If
                    we make changes to these Terms, we will post the revised Terms on the Services and update the
                    &ldquo;Last
                    Updated&rdquo; date at the top of these Terms. If you do not terminate this Agreement before the date
                    the
                    revised Terms become effective, your continued access to or use of the Services will constitute
                    acceptance
                    of the revised Terms.</span></p>
            <p class="c1"><span class="c0">Special terms or rules may apply to some Services. Any such terms are in addition
                    to
                    these Terms. In the event of any conflict or inconsistency between these Terms, our Privacy Notice, and
                    any
                    rules, restrictions, limitations, terms and/or conditions that may be communicated to users of the
                    Services,
                    Botsitivity shall determine which rules, restrictions, limitations, terms and/or conditions shall
                    control
                    and prevail, in our sole discretion, and you specifically waive any right to challenge or dispute such
                    determination.</span></p>
            <h3 class="c8" id="h.26y4esaaqo0m"><span class="c0">9.6 No Third-Party Beneficiaries</span></h3>
            <p class="c1"><span class="c0">This Agreement does not, and is not intended to, confer any rights or remedies
                    upon
                    any person other than the parties hereto.</span></p>
            <h3 class="c8" id="h.1yokk18rt2zi"><span class="c0">9.7 No Waiver and Severability</span></h3>
            <p class="c1"><span class="c4">Botsitivity</span><span class="c0">&rsquo;s failure to enforce a provision of
                    this
                    Agreement is not a waiver of its right to do so later or to enforce any other provision. Except as
                    expressly
                    set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement
                    will
                    be without prejudice to its other remedies under this Agreement or otherwise permitted under law.</span>
            </p>
            <p class="c1"><span class="c0">Except as explicitly provided herein, if any provision of this Agreement is held
                    to
                    be unenforceable for any reason, such provision will be reformed only to the extent necessary to make it
                    enforceable, and such decision will not affect the enforceability of such provision under other
                    circumstances, or of the remaining provisions hereof under all circumstances.</span></p>
            <h3 class="c8" id="h.rfwc59ulhj84"><span class="c0">9.8 Governing Law and Venue</span></h3>
            <p class="c1"><span class="c0">These Terms will be interpreted in accordance with the laws of the United States
                    of
                    America, without regard to conflict-of-law provisions. Judicial proceedings (other than small claims
                    proceedings) that are excluded from the Arbitration Agreement must be brought in the state or federal
                    courts
                    unless we both agree to some other location. You and we both consent to venue and personal
                    jurisdiction.</span></p>
            <h3 class="c8" id="h.i14e86h6lwpy"><span class="c6">Entire Agreement</span></h3>
            <p class="c1"><span class="c0">Except as it may be supplemented by additional terms and conditions, policies,
                    guidelines or standards as provided herein, this Agreement constitutes the entire agreement between
                    Botsitivity and you pertaining to the subject matter hereof, and supersedes any and all prior oral or
                    written understandings or agreements between Botsitivity and you in relation to the access to and use of
                    the
                    Services.</span></p>
            <p class="c1 c9"><span class="c10"></span></p>
            <p class="c5 c9"><span class="c10"></span></p>
            <p class="c5 c9"><span class="c10"></span></p>
            <p class="c5 c9"><span class="c10"></span></p>
        </div>
    )
}
export default TermsOfService