import './BylawsPage.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Bylaws from '../../components/Bylaws/Bylaws.js'
import React, { useEffect } from 'react';

function BylawsPage() {



  return (
    <div className="bylaws">
      <Menu/>
      <div className="bylawcenter">
        <Bylaws/>
      </div>
      <Footer/>
    </div>
  );
}

export default BylawsPage;
