import './Furtherexplanation.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import Phone from "../../images/phone.png"
import Globe from "../../images/globe.png"
import ThreeByThree from "../../images/3by3.png"
import React, { useEffect } from 'react';

function UnderConstruction() {


  useEffect(async()=>{  
    let parsed = window.location.href.split("#")
    
    if (parsed.length==2){
      if (parsed[1]=="bot"){
        document.getElementById("bot").scrollIntoView();
      }else if (parsed[1]=="team"){
        document.getElementById("team").scrollIntoView();
      }
      }else if (parsed[1]=="donate"){
        document.getElementById("donate").scrollIntoView();
      }
    }
  )

  return (
    <div className="furtherOuter">
      <Menu />
      <div className="furtherInner">

        <div className="furtherSection" id="bot">
          <div className="furtherLeft">
            
            <h1 className="furtherh1">How Our Bot Works</h1>
            <p className="furtherp">
              Our A.I. and Ruled-based bot uses past
              conversations with users and training from volunteers
              to create a high quality chatting experience.
            </p>
            <div className="furtherInnerDesc">
              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">Brighten Mood</h3>
                <p className="furtherDescText">
                  Our bot allows you to chat and rant to it, while giving satisfactory answers. It will
                  reassure you and talk to you as a friend, boosting your mood.
                </p>
              </div>
              
              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">Relieve Stress</h3>
                <p className="furtherDescText">
                  BOTSITIVITY serves as a therapist and can give you advice. It can also give you
                  feedback on your work and propose solutions to you problems.
                </p>
              </div>

              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">New Friends</h3>
                <p className="furtherDescText">
                  Our bot serves as a friend to all users. It has compassion and will provide you with
                  comfort and support.
                </p>
              </div>

              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">Potentially Prevent Suicide & Self Harm</h3>
                <p className="furtherDescText">
                  BOTSITIVITY will link you to professionals if signs of danger is detected.
                  Additionally, our bot will provide you with advice and help in a difficult situation.
                </p>
              </div>

            </div>
          </div>
          <div className="furtherRight">
            <div className="furtherImageCenter">
              <img className="floatingV2 furtherSize" src={Phone}></img>
            </div>
          </div>
        </div>

        <div className="furtherSection furtherStrippedColor" id="team">
          <div className="furtherLeft">
            <div className="furtherImageCenter">
              <img className="floatingV2 furtherSize" src={ThreeByThree}></img>
            </div>
          </div>
          <div className="furtherRight">

              <h1 className="furtherh1Left">Benefits In Joining Our Team</h1>
              <p className="furtherpLeft">
                BOTSITIVITY offers a volunteering program in which you
                help train our bot by inputting data. If members of
                BOTSITIVITY recognize you as an exemplary volunteer,
                you may be given the opportunity to join the official team.
              </p>
              <div className="furtherInnerDescLeft">
                <div className="furtherInnerRightColor">
                  <h3 className="furtherInnerTitleLeft">Service Hours</h3>
                  <p className="furtherDescTextLeft">
                    BOTSITIVITY offers nationally recognized volunteer hours. These hours can be
                    written into applications and resumés. Additionally, we offer the Presidental
                    Service Award including Bronze, Silver, Gold and Lifetime. Volunteers can receive 
                    service hours in many different ways, such as providing BOTSITIVITY with data by
                    answering questions, coding, creating art for BOTSITIVITY, and managing BOTSITIVITY's 
                    social media platforms. (For more information, join an interest meet)
                  </p>
                </div>
                
                <div className="furtherInnerRightColor">
                  <h3 className="furtherInnerTitleLeft">Leadership Positions</h3>
                  <p className="furtherDescTextLeft">
                    You can be given opportunity to join the official team if your application is accepted. During your experience
                    in BOTSITIVITY, you will gain skills such as machine learning, coding, data analysis, social media marketing,
                    infographic making, and much more. Be prepared to work as a team and have responsibility over your tasks!
                  </p>
                </div>

                <div className="furtherInnerRightColor">
                  <h3 className="furtherInnerTitleLeft">Join & Create Events</h3>
                  <p className="furtherDescTextLeft">
                    BOTSITIVITY allows you to join us in creating special events for our servers. Both volunteers and the official team
                    can participate in the process. It is an amazing experience to add in applications and learn both hard and soft
                    skills through our talented team.
                  </p>
                </div>

              </div>

          </div>
          
        </div>

        <div className="furtherSection" id="donate">
          <div className="furtherLeft">
            
            <h1 className="furtherh1">Donate to Support Our Cause</h1>
            <div className="donateOuter">
              <div>
                <h6 className="donateText">Select Amount:</h6>
                <div className="donateInner">
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">$1</button></div>
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">$10</button></div>
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">$30</button></div>
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">$5</button></div>
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">$20</button></div>
                  <div className="donateButtonDivs"><button type="button" className="donateAmountBtn">Other</button></div>
                </div>
              </div>
              <div>
                <h6 className="donateText">Name:</h6>
                <div className="donateInputDiv">
                  <input type="text" class="donateInput"></input>
                </div>
              </div>
              <div>
                <div>
                  <h6 className="donateText">Payment Method:</h6>
                  <div className="donatePaymentDiv">
                    <div className="donatePaymentBtnDiv"><button type="button" className="donatePaymentBtn">Paypal</button></div>
                    <div className="donatePaymentBtnDiv"><button type="button" className="donatePaymentBtn">Credit Card</button></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="furtherInnerDesc">
              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">BOTSITIVITY's Goals</h3>
                <p className="furtherDescText">
                  We want to provide the highest possible quality chatting experience for our users
                  through the usage of A.I. and Rule Based technology. This could allow us to
                  implement free therapy, counseling, consulting, both physical and mental help,
                  and of course engaging conversations will greatly benefit the public's mental
                  health.
                </p>
              </div>
              
              <div className="furtherInnerLeftColor">
                <h3 className="furtherInnerTitle">We Need Your Help</h3>
                <p className="furtherDescText">
                  We're a nonprofit that relies on support from people like you. Whether it's
                  emotional support or just donating a dollar, your help will be greatly appreciated.
                  If everyone reading this can donate juts a dollar, BOTSITIVITY will surely
                  complete our vision and thrive for years.
                </p>
              </div>

            </div>
          </div>
          <div className="furtherRight">
            <div className="furtherImageCenter">
              <img className="floatingV2 furtherSize" src={Globe}></img>
            </div>
          </div>
        </div>

        <div className="furtherSection">
          <div className="furtherLeft"></div>
          <div className="furtherRight"></div>
        </div>
        
      </div>
      <Footer/>
    </div>
  );
}

export default UnderConstruction;
