import './AboutUs.css';
import Menu from '../../components/Menu/Menu.js'
import Footer from '../../components/Footer/Footer.js'
import React, { useState, useEffect} from 'react'
import Vision from "../../images/vision.svg"

function AboutUs() {

  return (
    <div>
      <Menu />
      <h1 style={{display:'none'}}>Our Vision</h1>
      <div className="AboutUsBackground">
        <img src={Vision}></img>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
