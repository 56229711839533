import "./home.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import Loading from "../../components/Loading/loading";
import Cookies from "universal-cookie";
import axios from "axios";
import { useEffect, useState } from "react";

import VolunteerPicture from "../../images/volunteerTask.png";
import TasksPicture from "../../images/tasks.png";
import ReviewPicture from "../../images/review.png";
import OauthPicture from "../../images/discord.png";
import ByLaws from "../../images/bylaws.png";
import ComingSoon from "../../images/comingsoon.png";
import Certificate from "../../images/certificate.png";
import Email from "../../images/email.png";

import User from "../../images/user.png";
import Star from "../../images/star.png";

function Home() {
  const [displayOptions, setOptions] = useState([]);
  const [internalPaths, setIntervalPaths] = useState({
    tasks: { path: "/tasks", src: TasksPicture },
    volunteers: { path: "/volunteers", src: VolunteerPicture },
    redeem: { path: "/Redeem", src: Certificate },
    reviews: { path: "/reviews", src: ReviewPicture },
    discord: { path: "/oauth", src: OauthPicture },
    bylaws: { path: "/bylaws", src: ByLaws },
    email: { path: "/MassEmail", src: Email },
  });

  const cookies = new Cookies();
  const session = cookies.get("session");

  const chooseAccountType = async (type) => {
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/chosenAccount`;
    if (window.location.hostname == "localhost")
      postURL = `${window.location.protocol}//${window.location.hostname}:2000/chosenAccount`;
    await axios.post(postURL, { session: session, chosenRole: type });
    await window.location.reload();
  };

  useEffect(async () => {
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/userinfo`;
    if (window.location.hostname == "localhost")
      postURL = `${window.location.protocol}//${window.location.hostname}:2000/userinfo`;
    let userinfo = await axios.post(postURL, { session: session });

    if (userinfo.data.code == 403) {
      window.location.replace("/suspended");
    }

    let pathDivs = [];
    const user = userinfo.data.payload;

    if (user.discordID) {
      let backup = internalPaths;
      delete backup.discord;
      setIntervalPaths(backup);
    }

    if (user.firstTime) {
      pathDivs.push(
        <div
          className="chooseRoleDiv"
          onClick={() => {
            chooseAccountType("Volunteer");
          }}
        >
          <h2>Become a volunteer</h2>
          <img src={Star}></img>
        </div>
      );
      pathDivs.push(
        <div
          className="chooseRoleDiv"
          onClick={() => {
            chooseAccountType("User");
          }}
        >
          <h2>Become a user</h2>
          <img src={User}></img>
        </div>
      );
    } else {
      if (user.role == "Admin" || user.role == "Moderator") {
        Object.keys(internalPaths).forEach((key) => {
          key = key.toString();
          pathDivs.push(
            <div
              className="taskCard"
              onClick={() => {
                window.location.href = `${internalPaths[key].path}`;
              }}
            >
              <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
              <img src={internalPaths[key].src}></img>
            </div>
          );
        });
      } else if (user.role == "User") {
        pathDivs.push(
          <div className="taskCard">
            <h2>Coming Soon</h2>
            <img src={ComingSoon}></img>
          </div>
        );
      } else {
        let noaccess = ["volunteers", "reviews", "email"];
        Object.keys(internalPaths).forEach((key) => {
          key = key.toString();
          if (!noaccess.includes(key)) {
            pathDivs.push(
              <div
                className="taskCard"
                onClick={() => {
                  window.location.href = `${internalPaths[key].path}`;
                }}
              >
                <h2>{key.charAt(0).toUpperCase() + key.slice(1)}</h2>
                <img src={internalPaths[key].src}></img>
              </div>
            );
          }
        });
      }
    }

    setOptions(pathDivs);

    document.getElementById("loader").classList.toggle("stopLoading");
  }, []);

  return (
    <div>
      <Loading id="loader" />
      <Menu />
      <div className="tasks">{displayOptions}</div>
      <Footer />
    </div>
  );
}

export default Home;
