import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Menu.css"
import Cookies from "universal-cookie";
import sleep from "../../js/basics.js"

function Menu(){

    const mailto = "mailto:general@botsitivity.org"

    const cookies = new Cookies()

    const [ signUpPath , setSignUpPath ] = useState("/signup")
    const [ loginPath , setLoginPath ] = useState("/login")
    const [ signUpText , setSignUpText ] = useState("SIGN UP")
    const [ loginText , setLoginText ] = useState("LOG IN")

    useEffect(async()=>{
        if (cookies.get('session')){
            setSignUpText("MY ACCOUNT")
            setLoginText("LOG OUT")
            setSignUpPath("/userhome")
            setLoginPath("logout")
        }
        
        const hamMenu = document.getElementById("hamburger")
        const hamburgerCover = document.getElementById("hamburgerMenu")
        const html = document.getElementsByTagName("html")[0]
        var menuOpen = false
    
        hamMenu.addEventListener("click", ()=>{
            if (menuOpen){
                menuOpen = false
                hamMenu.classList.remove('openMenu')
                hamburgerCover.classList.remove("openHamburger")
                html.style.overflow = "visible"
            } else {
                menuOpen = true
                hamMenu.classList.add('openMenu')
                hamburgerCover.classList.add("openHamburger")
                html.style.overflow = "hidden"
            }
        })
    })

    const hamburgerGoto = async(option)=>{
        document.getElementsByTagName("html")[0].style.overflow = "visible"
        document.getElementById("hamburger").click()

        await sleep(450)

        if (option=="HOME") window.location.replace("/")
        else if (option=="ABOUT") window.location.replace("/about")
        else if (option=="CONTACT") {
            window.location = mailto
        }
        else if (option=="SIGNUP") window.location.replace(signUpPath)
        else if (option=="LOGIN") window.location.replace(loginPath)
    }

    return(
        <div className="outerMenu">
            <div className="innerMenu">
                <ul className="menuSections">
                    <li><Link to="/about" className="menuText">ABOUT</Link></li>
                    <li><Link to="#" className="menuText underline" onClick={(e)=>{window.location = mailto; e.preventDefault()}}>CONTACT US</Link></li>
                </ul>
                <ul className="middleSection">
                    <li><Link to="/" className="menuText specialText">BOTSITIVITY</Link></li>
                </ul>
                <ul className="menuSections">
                    <li><Link to={signUpPath} className="menuText">{signUpText}</Link></li>
                    <li><Link to={loginPath} className="menuText">{loginText}</Link></li>
                </ul>
                <ul className="menuSections" id="hamburgerOuter">
                    <div className="hamburger" id="hamburger">
                        <div className="hamburgerLine"></div>
                    </div>
                </ul>
            </div>
            <div className="hamburgerMenu" id="hamburgerMenu">
                <ul className="hamburgerSections">
                    <li onClick={()=>{hamburgerGoto("HOME")}}>HOMEPAGE</li>
                    <li onClick={()=>{hamburgerGoto("ABOUT")}}>ABOUT</li>
                    <li onClick={()=>{hamburgerGoto("CONTACT")}}>CONTACT US</li>
                    <li onClick={()=>{hamburgerGoto("SIGNUP")}}>{signUpText}</li>
                    <li onClick={()=>{hamburgerGoto("LOGIN")}}>{loginText}</li>
                </ul>
            </div>
        </div>
    )
    
    }
export default Menu