import React from "react";
import "./Checkbox.css"

const Checkbox = (props)=> 
<div className="checkBoxDiv">
    <label class="container">{props.content}
        <input type="checkbox" id={props.id}/>
        <span class="checkmark"></span>
    </label>
    {/* <div className="checkBoxLabel">
        <h6 className>{props.content}</h6>
    </div> */}
</div>

export default Checkbox