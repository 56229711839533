import React from "react"
import "./buttonWhite.css"

const Button = (props)=>{
    return(
        <div style={{height: (parseInt(props.Height.replace("px","").replace("%",""))+5).toString()+"px"}}>
            <button className="whiteBtn" style={{"height":props.Height,"width":props.Width,"borderRadius":(parseInt(props.Height.replace("px","").replace("%",""))/2).toString()+"px"}} onClick={props.func}>{props.content}</button>
        </div>
    );
}
export default Button