import React from "react";
import "./Floating.css"
import Arrow from "../../images/down-chevron.png"

const scrollTo = ()=>{
    document.getElementById("persuade#1").scrollIntoView()
}

const FloatingArrow = ()=>{
    return (
        <div className="floatingarrow" onClick={scrollTo}>
            <img src={Arrow}></img>
        </div>
    )
}

export default FloatingArrow
