import React from "react";
import "./content1.css"

const content1 = (props)=> <div className="content-div" style={{ backgroundColor: "rgb(252, 232, 151)",backgroundRepeat: "no-repeat", height: "100vh",}}>
    
    <div style={{position: "relative", top:"30%"}}>
        <h1>{props.name}</h1>
        {props.children}
    </div>

</div>
export default content1