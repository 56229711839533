import './reviews.css';
import Menu from "../../components/Menu/Menu.js"
import Footer from "../../components/Footer/Footer.js"
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Loading from '../../components/Loading/loading';
import Cookies from "universal-cookie";

var currentVolunteer=null;

function Reviews() {

    const [ allReviews, setReviews ] = useState([])
    const [ displayReview, setDisplayReivew ] = useState(<div className="volunteerEmptyFont">Select a review to start</div>)
    const [pvh, setpvh] = useState(0)
    const [vha, setvha] = useState(0)
    const [vhr, setvhr] = useState(0)

    const cookies = new Cookies()
    const session = cookies.get("session")

    const preformAction = async(choice, review)=>{
        let manageReview;
        if (choice=="approve"){
            let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/managereview`
            if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/managereview`
            manageReview = await axios.post(postURL,{ choice: true, responseId: review.id ,session: session })
        }else if(choice=="reject"){
            let postURL=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/managereview`
            if (window.location.hostname=="localhost") postURL = `${window.location.protocol}//${window.location.hostname}:2000/managereview`
            manageReview = await axios.post(postURL,{ choice: false, responseId: review.id ,session: session })
            setDisplayReivew(<div style={{color: "green"}}>Successfully unsuspended Volunteer</div>)
        }
        if (manageReview.data.code!=200){
            setDisplayReivew(<div style={{color: "red"}}>{manageReview.data.message}</div>)
        }else{
            setDisplayReivew(<div style={{color: "green"}}>Successfully Updated Review</div>)
            setvha( Math.round( (vha+0.1) * 100) / 100)
            document.getElementById("reviews").removeChild(document.getElementById(`r${review.id}`))
        }
        
    }

    const getActionButton = (review)=>{
        return <div className="actionButtonDiv">
            <button type="button" style={{backgroundColor:"rgb(250, 76, 76)", color:"white"}} className="actionButtonReview" onClick={()=>{preformAction("reject", review)}}>Reject</button>
            <button type="button" style={{backgroundColor:"green", color:"white"}} className="actionButtonReview" onClick={()=>{preformAction("approve", review)}}>Approve</button>
        </div>
    }

    const showVolunteer = (review)=>{

        let type = "prompt" in review.task ? "Prompt" : "Description"
        setDisplayReivew(
            <div className="reviewDisplayed">
                <div><label>{type}: </label>{review.task[type.toLowerCase()]}</div>
                <div><label>Response: </label>{review.task.response}</div>
                {getActionButton(review)}
            </div>
        )
    }
    
    
    const selectReview = (review)=>{
        document.getElementById("reviews").childNodes.forEach((elements)=>{
            elements.classList.remove("selectedReviews")
        })
        document.getElementById(`r${review.id}`).classList.toggle("selectedReviews")
        showVolunteer(review)
        currentVolunteer = review.id
    }

    const renderReviews = ()=>{
        let processReviews = []
        allReviews.forEach((review)=>{
            processReviews.push(
                <div className="reviews" onClick={ ()=>{selectReview(review)} } id={`r${review.id}`}>
                    <div>{review.task.prompt}</div>
                    <div className="subname">{review.task.fname} {review.task.lname}</div>
                </div>
            )
        })
        return processReviews
    }

    useEffect(async()=>{
        let postURL1=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/fetchreview`
        if (window.location.hostname=="localhost") postURL1 = `${window.location.protocol}//${window.location.hostname}:2000/fetchreview`
        let fetchReviews = await axios.post(postURL1,{ session: session })
        
        let postURL2=`${window.location.protocol}//${process.env.REACT_APP_API_URL}/userinfo`
        if (window.location.hostname=="localhost") postURL2 = `${window.location.protocol}//${window.location.hostname}:2000/userinfo`
        let fetchUser = await axios.post(postURL2,{ session: session })
        const user = fetchUser.data.payload

        setpvh( Math.round(user.PendingVolunteerHours * 100) / 100 )
        setvha( Math.round(user.VolunteerHoursEarned * 100) / 100 )
        setvhr( Math.round(user.VolunteerHoursRedeemed * 100) / 100 )

        setReviews(fetchReviews.data.payload)
        document.getElementById("loader").classList.toggle("stopLoading")
    }, [])

    return ( 
        <div>
            <Loading id="loader"/>
            <Menu/>
                <div className="displaystatsReviews">
                    <div><label>Pending Volunteer Hours: </label><div className="hoursDisplayed" id="pvh">{pvh}</div></div>
                    <div><label>Volunteer Hours Approved: </label><div className="hoursDisplayed">{vha}</div></div>
                    <div><label>Volunteer Hours Redeemd: </label><div className="hoursDisplayed">{vhr}</div></div>
                </div>
                <div className="volunteerHome">
                    <div className="displayReviews" id="reviews">{renderReviews()}</div>
                    <div className="currentReview">{displayReview}</div>
                </div>
            <Footer/>
        </div>
    );
}

export default Reviews;