import React from "react";
import "./persuade.css"
import Chat from "../../images/chat.png"
import Donating from "../../images/donating.png"
import PVSA from "../../images/pvsa.jpg"
import { Link } from "react-router-dom";
import UnderConstruction from "../../pages/UnderConstruction/UnderConstruction.js"

const Persuade = (props)=> <div className="content-div" style={{ backgroundColor: "white",backgroundRepeat: "no-repeat", height: "110%"}}>

    <div className="section coloredStripe2 hidden" id="persuade#1">
        <div className="leftSection lowerExplanations">
            <div className="titleBox">
                <h2>Support our cause</h2>
            </div>
            <h1 className="websiteDescription">
                BOTSITIVITY is a <strong>Certified Presidential Service Award and 501c3 tax-exempt nonprofit organization</strong>.
                Our mission is to raise the support for mental health through the usage of 
                machine learning. Through public funding, we are able to
                support this goal and help communities worldwide.

            </h1>
            <div class="viewHereContainer"><Link to="/Furtherexplanation#donate">View here</Link></div>
        </div>
        <div className="rightSection">
            <img className="images floatingV2" style={{borderRadius: "50%"}} src={PVSA} alt="Presidential Service Award Logo"></img>
        </div>
    </div>
    <div className="section coloredStripe1 hidden" id="persuade#2">
        <div className="leftSection lowerExplanations">
            <div className="titleBox">
                <h2>Volunteer and join our team</h2>
            </div>
            <p>
                Our team offers nationally recognized volunteer hours through a
                series of bot training and virtual chatting. This virtual experience
                allows you to connect to like-minded peers worldwide and gain
                soft and hard skills. Volunteers can contribute in many different ways,
                such as providing data by answering questions, coding, and more!
            </p>
            <div className="viewHereContainer"><Link to="/Furtherexplanation#team">View here</Link></div>
        </div>
        <div className="rightSection">
            <img className="images floatingV2" src={Donating} alt="Donate image"></img>
        </div>
    </div>
    <div className="section coloredStripe2 hidden" id="persuade#3">
        <div className="leftSection">
            <img className="images floatingV2" src={Chat} alt="Chatting"></img>
        </div>
        <div className="rightSection lowerExplanations">
            <div className="titleBox">
                <h2>Chat with our bot privately</h2>
            </div>
            <p>
                BOTSITIVITY's participation with countless conversations
                will give you the highest quality chatting experience. Our bot has the
                potential to brighten your mood, make a new friend, and even
                prevent suicide.
            </p>
            <div className="viewHereContainer"><Link to="/Furtherexplanation#bot">View here</Link></div>
        </div>
    </div>
</div>

export default Persuade