import React from "react";
import logo from "../../images/logo.png"
import "./Footer.css"
import { Link } from "react-router-dom";

const Menu = ()=> <div className="footer-container">        
    <footer className="note">
        <div>
            <ul className="footerOptions">
                <li><Link className="footerText" to="/Statements#disclaimers" smooth={true}>Disclaimers</Link></li>
                <li><Link className="footerText" to="/Statements#privacypolicy" smooth={true}>Privacy Policy</Link></li>
                <li><Link className="footerText" to="/Statements#cookiestatement" smooth={true}>Cookie Statement</Link></li>
                <li><Link className="footerText" to="/team">Our Team</Link></li>
            </ul>
        </div>
    </footer>
</div>

export default Menu