import "./volunteers.css";
import Menu from "../../components/Menu/Menu.js";
import Footer from "../../components/Footer/Footer.js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading/loading";
import Cookies from "universal-cookie";

var currentVolunteer = null;

function Tasks() {
  const [allVolunteers, setVolunteers] = useState([]);
  const [displayVolunteer, setDisplayVolunteer] = useState(
    <div className="volunteerEmptyFont">Select a volunteer to start</div>
  );

  const cookies = new Cookies();
  const session = cookies.get("session");

  const preformAction = async (choice, volunteer) => {
    let manageAccount;
    if (choice == "suspend") {
      let postURL = `${
        window.location.protocol
      }//${process.env.REACT_APP_API_URL}/manageAccount`;
      if (window.location.hostname == "localhost")
        postURL = `${window.location.protocol}//${window.location.hostname}:2000/manageAccount`;

      manageAccount = await axios.post(postURL, {
        action: "suspend",
        accountId: volunteer.userId,
        session: session,
      });
      setDisplayVolunteer(
        <div style={{ color: "red" }}>Successfully Suspended Volunteer</div>
      );
      volunteer.suspended = true;
    } else if (choice == "unsuspend") {
      let postURL = `${
        window.location.protocol
      }//${process.env.REACT_APP_API_URL}/manageAccount`;
      if (window.location.hostname == "localhost")
        postURL = `${window.location.protocol}//${window.location.hostname}:2000/manageAccount`;

      manageAccount = await axios.post(postURL, {
        action: "unsuspend",
        accountId: volunteer.userId,
        session: session,
      });
      setDisplayVolunteer(
        <div style={{ color: "green" }}>Successfully unsuspended Volunteer</div>
      );
      volunteer.suspended = false;
    }
    if (manageAccount.data.code != 200) {
      setDisplayVolunteer(
        <div style={{ color: "red" }}>{manageAccount.data.message}</div>
      );
    }
  };

  const getActionButton = (volunteer) => {
    return volunteer.suspended ? (
      <button
        type="button"
        style={{ backgroundColor: "green", color: "white" }}
        className="actionButton"
        onClick={() => {
          preformAction("unsuspend", volunteer);
        }}
      >
        Unsuspend
      </button>
    ) : (
      <button
        type="button"
        style={{ backgroundColor: "rgb(250, 76, 76)", color: "white" }}
        className="actionButton"
        onClick={() => {
          preformAction("suspend", volunteer);
        }}
      >
        Suspend
      </button>
    );
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const addVolunteerHours = async () => {
    let hours = parseFloat(document.getElementById("amt").value);
    if (hours != NaN) {
      let postURL = `${
        window.location.protocol
      }//${process.env.REACT_APP_API_URL}/addHours`;
      if (window.location.hostname == "localhost")
        postURL = `${window.location.protocol}//${window.location.hostname}:2000/addHours`;

      let response = await axios.post(postURL, {
        session,
        targetUserID: allVolunteers[currentVolunteer].userId,
        amt: hours,
      });

      if (response.data.code == 200) {
        document.getElementById("addHoursResult").innerHTML =
          "Successfully applied changes";
      } else {
        document.getElementById("addHoursResult").innerHTML =
          response.data.message;
      }

      await sleep(1000);

      window.location.reload();
    }
  };

  const showVolunteer = (id) => {
    let volunteer = allVolunteers[id];
    setDisplayVolunteer(
      <div className="centerShowVolunteer">
        <div className="showCurrentVolunteer">
          <div>
            <label>Name: </label>
            {volunteer.fname} {volunteer.lname}
          </div>
          <div>
            <label>Role: </label>
            {volunteer.role}
          </div>
          <div>
            <label>Email: </label>
            {volunteer.email}
          </div>
          <div>
            <label>UserID: </label>
            {volunteer.userId}
          </div>
          <div>
            <label>Verified: </label>
            {volunteer.isVerified ? "Yes" : "No"}
          </div>
          <div>
            <label>Suspended: </label>
            {volunteer.suspended ? "Yes" : "No"}
          </div>
          <div>
            <label>Pending Volunteer Hours: </label>
            {volunteer.PendingVolunteerHours}
          </div>
          <div>
            <label>Approved Volunteer Hours: </label>
            {Math.round(volunteer.VolunteerHoursEarned * 100) / 100}
          </div>
          <div>
            <label>Redeemed Volunteer Hours: </label>
            {volunteer.VolunteerHoursRedeemed}
          </div>
        </div>
        <div className="volunteerAction">
          {getActionButton(volunteer)}
          <div className="editVolunteerHoursBox" id="addHoursResult">
            <div>
              <label>Amount of Volunteer hours to add:</label>
              <input
                className="editVolunteerHours"
                type={"number"}
                id="amt"
              ></input>
            </div>
            <button className="addVolunteerHours" onClick={addVolunteerHours}>
              Add Volunter Hours
            </button>
          </div>
        </div>
      </div>
    );
  };

  const selectedVolunteer = (id) => {
    document.getElementById("volunteers").childNodes.forEach((elements) => {
      elements.classList.remove("selectedVolunteers");
    });
    document.getElementById(`vol${id}`).classList.toggle("selectedVolunteers");
    showVolunteer(id);
    currentVolunteer = id;
  };

  const renderVolunteers = () => {
    let vol = [];
    let index = 0;
    console.log(allVolunteers);
    allVolunteers.sort(function (a, b) {
      var nameA = a.fname.toUpperCase(); // ignore upper and lowercase
      var nameB = b.fname.toUpperCase(); // ignore upper and lowercase
      var nameAA = a.lname.toUpperCase(); // ignore upper and lowercase
      var nameBB = b.lname.toUpperCase(); // ignore upper and lowercase

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (nameA === nameB) {
        if (nameAA < nameBB) {
          return -1;
        }
        if (nameAA > nameBB) {
          return 1;
        }
      }
      // names must be equal
      return 0;
    });

    console.log(allVolunteers);
    console.log("steven op");
    allVolunteers.forEach((volunteer) => {
      let copied = index;
      vol.push(
        <div
          className="volunteers"
          onClick={() => {
            selectedVolunteer(copied);
          }}
          id={`vol${index}`}
        >
          <div>
            {volunteer.fname} {volunteer.lname}
          </div>
          <div className="subemail">{volunteer.email}</div>
        </div>
      );
      index++;
    });
    return vol;
  };

  useEffect(async () => {
    let postURL = `${
      window.location.protocol
    }//${process.env.REACT_APP_API_URL}/getvolunteers`;
    if (window.location.hostname == "localhost")
      postURL = `${window.location.protocol}//${window.location.hostname}:2000/getvolunteers`;
    let fetchVolunteers = await axios.post(postURL, { session: session });
    setVolunteers(fetchVolunteers.data.payload);
    document.getElementById("loader").classList.toggle("stopLoading");
  }, []);

  return (
    <div>
      <Loading id="loader" />
      <Menu />
      <div className="volunteerHome">
        <div className="displayVolunteer" id="volunteers">
          {renderVolunteers()}
        </div>
        <div className="currentVolunteer">{displayVolunteer}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Tasks;
