import React, {useState, useEffect} from "react";
import "./HomeScreen.css"
import Switch from "../Switch/switch.js"
import YellowButton from "../ButtonYellow/buttonYellow.js";
import Button from "../ButtonBlue/buttonBlue.js"
const JoinLink = "https://forms.gle/KShrqPgWawZsWPvLA"
const Chat = "http://bopy.botsitivity.org" 
const Ideathon = "https://forms.gle/7QjJUNnnUWV4VRrJA"

const HomeScreen = (props)=>{ 

    var typedTextSpan = null;

    const textArray = ["Everyone."," is a safe and secured space for ", " you to communicate to our bot."]
    const typingDelay = 90
    const erasingDelay = 100
    const newTextDelay = 2000
    let textArrayIndex = 0
    let charIndex = 0

    const type = ()=>{
        try{
            if (charIndex < textArray[textArrayIndex].length){
                typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex)
                charIndex++
                setTimeout(type, typingDelay)
            }else{
                if (textArrayIndex<textArray.length){
                    
                    charIndex = 0
                    textArrayIndex++
                    
                    if (textArrayIndex<textArray.length){
                        typedTextSpan = document.querySelector(`.typed-text${textArrayIndex+1}`)
                        document.querySelector(`.Homecursor${textArrayIndex}`).style.visibility = "hidden"
                        document.querySelector(`.Homecursor${textArrayIndex+1}`).style.visibility = "visible"
                        setTimeout(type, typingDelay)
                    }
                }
                
            }
        }catch (err){
            console.log(err)
        }
    }

    const redirect = ()=>{
        window.location.replace("/status")
    }

    const [ buttonWidth, setButtonWidth ] = useState("300px")
    const [ buttonHeight, setButtonHeight ] = useState("100px")

    useEffect(async()=>{
        if (window.innerWidth < 600) {
            setButtonWidth("200px")
            setButtonHeight("75px")
        }
        typedTextSpan = document.querySelector(".typed-text1")
        setTimeout(type, 1000)
    },[])

    return(
        <div className="HomeDiv">
            <div className="InnerHomeDiv">
                <div className="HomeLSection">
                    <div className="centerCollective">
                        <div className="collectiveCircles">
                            <div className="HomeCircle"></div>
                            <div className="HomeCircle"></div>
                            <div className="HomeCircle"></div>
                            <div className="HomeCircle"></div>
                            <div className="HomeCircle"></div>
                            <div className="HomeCircle"></div>
                        </div>
                    </div>
                </div>
                <div className="HomeRSection">
                    <div className="HomeRDescription">
                        <h1>Comfort and Support for</h1>
                        <h1><span className="typed-text1"></span><span className="Homecursor1">&nbsp;</span></h1>
                        <h3><b>
                            BOTSITIVITY<span className="typed-text2"></span><span className="Homecursor2">&nbsp;</span>
                            <br></br>
                            <span className="typed-text3"></span><span className="Homecursor3">&nbsp;</span>
                        </b></h3>
                        <div className="centerButton">
                            <YellowButton content="Check our Status" Height={buttonHeight} Width={buttonWidth} func={redirect}></YellowButton>
                            <YellowButton Height={buttonHeight} Width={buttonWidth} content="Join Us Now"func={()=>{ window.location.replace(JoinLink) }}></YellowButton>
                            <YellowButton Height={buttonHeight} Width={buttonWidth} content="Chat with Bopy"func={()=>{ window.location.href = Chat }}></YellowButton>
                            <YellowButton Height={buttonHeight} Width={buttonWidth} content="Register for our Ideathon"func={()=>{ window.location.replace(Ideathon) }}></YellowButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomeScreen